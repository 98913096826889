import React, { Component } from "react";
import { styled } from "@mui/material/styles";
import { Link, Routes, Route, Navigate } from "react-router-dom";

import MuiAppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import MuiTooltip from "@mui/material/Tooltip";
import MuiDrawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import MuiList from "@mui/material/List";
import MuiListItem from "@mui/material/ListItem";

import Logo from "../assets/images/Logo.svg";
import StarIcon from "../assets/images/Star.svg";
import ListIcon from "../assets/images/List.svg";
import ClockIcon from "../assets/images/Clock.svg";
import AvatarIcon from "../assets/images/Avatar.svg";
import ActiveStarIcon from "../assets/images/ActiveStar.svg";
import ActiveListIcon from "../assets/images/ActiveList.svg";
import ActiveClockIcon from "../assets/images/ActiveClock.svg";
import ActiveAvatarIcon from "../assets/images/ActiveAvatar.svg";
import DoubleArrowLeftIcon from "../assets/images/DoubleArrowLeft.svg";
import TextDashboard from "../assets/images/TextDashboard.svg";
import TextCancelaria from "../assets/images/TextCancelaria.svg";
import SonacomLogo from "../assets/images/DrawerSonacomLogo.svg";
import DrawerMenuIcon from "../assets/images/Menu.svg";

import clsx from "clsx";
import { withTranslation } from "react-i18next";
import i18n from "../translation/settings";
import Translate from "../components/Translate";

import MenuTrends from "../views/MenuTrends";
import MenuRequests from "../views/MenuRequests";
import MenuReqProc from "../views/MenuReqProc";
import MenuUsers from "../views/MenuUsers";

const drawerWidth = 380;
const ClosedDrawerWidth = 145;
const PageTransition = "margin 0.3s ease-out, width 0.3s ease-out";

const tooltipCompStyle = {
	tooltip: {
		sx: {
			fontFamily: "Rubik",
			fontWeight: 400,
			fontSize: 18,
			backgroundColor: "#2E3236",
			padding: "20px 26px",
			letterSpacing: "-0.03em",
			borderRadius: "12px"
		},
	},
	arrow: {
		sx: {
			backgroundColor: "#2E3236",
			width: "19px !important", height: "19px !important",
			borderRadius: "4px !important",
			transform: "translate(20px, 10px) !important",
			rotate: "45deg",
			"&:before": {
				content: "none"
			}
		}
	}
}

const Root = styled( 'div' )( () => ( {
	display: "flex", height: "100%",
	overflowX: "hidden"
} ) );
const AppBar = styled( MuiAppBar )( () => ( {
	width: `calc(100% - ${ClosedDrawerWidth}px - 44px)`,
	left: ClosedDrawerWidth,
	height: 160,
	//marginLeft: 60,
	display: "flex", flexDirection: "column",
	boxShadow: "none",
	backgroundColor: "#FFFFFF",
	paddingLeft: 0,
	//transition: PageTransition,
	"&.appBarShift": {
		width: `calc(100% - ${drawerWidth}px - 44px)`,
		left: drawerWidth,
		//transition: PageTransition
	}
} ) );
const Tooltip = styled( MuiTooltip )( () => ( {
	"&.MuiTooltip-popper": {
		marginLeft: 50,
		backgroundColor: "red",
		color: "red",
		"& .MuiTooltip-tooltip": {
			marginLeft: 50,
			backgroundColor: "red",
			color: 'red'
		}
	}
} ) );
const AppNameContainer = styled( 'div' )( () => ( {
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	gap: 8,
	color: "#313638", 
	marginLeft: 33
} ) );
const AppNameFirst = styled( 'img' )( () => ( {

} ) );
const AppNameSecond = styled( 'img' )( () => ( {

} ) );
const LogoutButton = styled( 'div' )( () => ( {
	fontFamily: "Rubik", fontWeight: 500, fontSize: 16,
	textTransform: "uppercase",
	display: "flex", justifyContent: "center", alignItems: "center",
	left: "calc( 100% - 125px )",
	padding: "10px 20px",
	width: 47,
	color: "#FFF",
	backgroundColor: "#0078FF",
	cursor: "pointer",
	borderRadius: 10
} ) );
const Drawer = styled( MuiDrawer )( () => ( {
	width: drawerWidth,
	transition: PageTransition,
	flexShrink: 0,
	"&.closedDrawer": {
		width: ClosedDrawerWidth,
		"& .MuiPaper-root": {
			width: ClosedDrawerWidth
		}
	},
	"& .MuiPaper-root": {
		overflowX: "hidden",
		width: drawerWidth,
		backgroundColor: "#F5F7F8",
		border: 0,
		transition: PageTransition
	}
} ) );
const CloseDrawer = styled( IconButton )( () => ( {
	margin: "auto 0 auto auto",
	padding: 20,
	backgroundColor: "#F5F7F8",
	position: "fixed",
	top: 45, left: drawerWidth - 33,
	visibility: "show",
	transition: "left 0.3s ease-out",
	"&:hover": {
		backgroundColor: "#F5F7F8"
	},
	"& > img": {
		padding: 5
	},
	"&.closedDrawer": {
		visibility: "hidden",
		left: ClosedDrawerWidth - 33
	}
} ) );
const OpenDrawer = styled( IconButton )( () => ( {
	margin: "auto"
} ) );
const MenuIcon = styled( 'img' )( () => ( {
	marginRight: 16
} ) );
const List = styled( MuiList )( () => ( {
	display: "flex", flexDirection: "column",
	"& a":{
		marginBottom: 5
	}
} ) );
const ListItem = styled( MuiListItem )( () => ( {
	width: "79%",
	fontSize: 14,
	padding: 0,
	margin: "auto",
	borderRadius: 12,
	"&.Mui-selected":{
		backgroundColor: "#FFFFFF",
		"& > div": {
			color: "#0078FF"
		},
	},
	"&.closedDrawer":{
		padding: "8px 16px !important",
		margin: "auto",
		"& > div": {
			margin: "5px auto "
		},
		"& img": {
			margin: "auto"
		},
		"&.Mui-selected":{
			backgroundColor: "#f5f7f8",
			"& > div": {
				color: "red"
			},
		},
		"&:hover": {
			backgroundColor: "#f5f7f8",
		},
		transition: "none"
	}
} ) );
const ListItemFlex = styled( 'div' )( () => ( {
	display: "flex", justifyContent: "center", alignItems: "center",
	margin: "15px 0px",
	padding: "5px 21px",
	fontFamily: "Rubik", fontSize: 18, fontWeight: 400,
	letterSpacing: -0.3,
	color: "#2E3236"
} ) );
const DevContainer = styled( 'div' )( () => ( {
	height: "100%",
	padding: 41,
	display: "flex", justifyContent: "end", alignItems: "flex-start",
	flexDirection: "column"
} ) );
const Content = styled( 'main' )( () => ( {
	paddingTop: 160,
	flexGrow: 1,
	transition: PageTransition,
	height: "auto",
	width: `calc(100% - ${ClosedDrawerWidth}px)`,
	"&.contentShift": {
		transition: PageTransition,
		marginLeft: 0,
		width: `calc(100% - ${drawerWidth}px)`,
	}
} ) );

class Dashboard extends Component {
	constructor( props ) {
		super( props );
		this.state = {
			open: false,
			pageId: "proc-times"
		};
		document.title = "Cancelaria Dashboard";
	}

	openDrawer = () => {
		this.setState( { open: true } );
	}
	closeDrawer = () => {
		this.setState( { open: false } );
	}
	changePage = ( pageId ) => {
		this.setState( { pageId: pageId } );
	}
	render() {
		return (
			<Root>
				<AppBar className={ clsx( { "appBarShift": this.state.open } ) }>
					<Toolbar style={{ margin: "auto 0 auto 26px" }}>
						{
							!this.state.open
							?
							<>
								<img alt={ "IconLogo" } src={ Logo }/>
								<AppNameContainer>
									<AppNameFirst src={ TextCancelaria } alt=""/>
									<AppNameSecond src={ TextDashboard } alt=""/>
								</AppNameContainer>
							</>
							:
							<div style={{ width: 211 }}></div>
						}
						<Translate/>
						<div style={{ margin: "auto 0 auto auto" }}>
							<LogoutButton onClick={ this.props.logout }>{ i18n.t( "btn_logout" ) }</LogoutButton>
						</div>
					</Toolbar>
				</AppBar>
				<Drawer
					variant="persistent"
					anchor="left"
					open={ true }
					className={ clsx( { "closedDrawer": !this.state.open } ) }
				>
					<div style={{ height: 160 }}>
						<div style={{ height: "inherit", display: "flex" }}>
						{
							this.state.open
							?
							<>
								<div style={{ display: "flex", flexDirection: "row", margin: "auto", width: "78%" }}>
									<img alt={ "IconLogo" } src={ Logo } width={ 66 } height={ 68 }/>
									<AppNameContainer>
										<AppNameFirst src={ TextCancelaria } alt=""/>
										<AppNameSecond src={ TextDashboard } alt=""/>
									</AppNameContainer>
								</div>
							</>
							:
							<OpenDrawer
							aria-label="open drawer"
							onClick={ this.openDrawer }
							edge="start"
							>
								<img src={ DrawerMenuIcon } alt=""/>
							</OpenDrawer>
						}
						</div>
					</div>
					<CloseDrawer className = { clsx( { "closedDrawer": !this.state.open } ) } onClick={ this.closeDrawer }>{ <img src={ DoubleArrowLeftIcon } alt=""/>}</CloseDrawer>

					<List>
						<Link style={ { textDecoration: "none", color: "black" } } to={ "benef-trends" }>
							<Tooltip
								PopperProps={{ sx: { inset: "0 auto auto 10px !important" } }}
								componentsProps={ tooltipCompStyle }
								disableHoverListener={ this.state.open }
								title={ i18n.t( "menu_1" ) }
								placement="right"
								arrow
							>
								<ListItem className={ clsx( { "closedDrawer": !this.state.open } ) } selected={ this.state.pageId === "benef-trends" } button key={ "benef-trends" }>
									<ListItemFlex><MenuIcon width={ 25 } height={ 25 } src={ this.state.pageId !== "benef-trends" ? StarIcon : ActiveStarIcon } alt="" style={{ marginRight: this.state.open ? 16 : 0 }}/>{
										this.state.open
										?
										i18n.t( "menu_1" )
										:
										null
									}</ListItemFlex>
								</ListItem>
							</Tooltip>
						</Link>
						<Link style={ { textDecoration: "none", color: "black" } } to={ "type-of-reqs" }>
							<Tooltip
								PopperProps={{ sx: { inset: "0 auto auto 10px !important" } }}
								componentsProps={ tooltipCompStyle }
								disableHoverListener={ this.state.open }
								title={ i18n.t( "menu_2" ) }
								placement="right"
								arrow
							>
								<ListItem className={ clsx( { "closedDrawer": !this.state.open } ) } selected={ this.state.pageId === "type-of-reqs" } button key={ "type-of-reqs" }>
									<ListItemFlex><MenuIcon width={ 25 } height={ 25 } src={ this.state.pageId !== "type-of-reqs" ? ListIcon : ActiveListIcon } alt="" style={{ marginRight: this.state.open ? 16 : 0 }}/>{
										this.state.open
										?
										i18n.t( "menu_2" )
										:
										null
									}</ListItemFlex>
								</ListItem>
							</Tooltip>
						</Link>
						<Link style={ { textDecoration: "none", color: "black" } } to={ "proc-times" }>
							<Tooltip
								PopperProps={{ sx: { inset: "0 auto auto 10px !important" } }}
								componentsProps={ tooltipCompStyle }
								disableHoverListener={ this.state.open }
								title={ i18n.t( "menu_3" ) }
								placement="right"
								arrow
							>
								<ListItem className={ clsx( { "closedDrawer": !this.state.open } ) } selected={ this.state.pageId === "proc-times" } button key={ "proc-times" }>
									<ListItemFlex><MenuIcon width={ 25 } height={ 25 } src={ this.state.pageId !== "proc-times" ? ClockIcon : ActiveClockIcon } alt="" style={{ marginRight: this.state.open ? 16 : 0 }}/>{
										this.state.open
										?
										i18n.t( "menu_3" )
										:
										null
									}</ListItemFlex>
								</ListItem>
							</Tooltip>
						</Link>
						<Link style={ { textDecoration: "none", color: "black" } } to={ "users" }>
							<Tooltip
								PopperProps={{ sx: { inset: "0 auto auto 10px !important" } }}
								componentsProps={ tooltipCompStyle }
								disableHoverListener={ this.state.open }
								title={ i18n.t( "menu_4" ) }
								placement="right"
								arrow
							>
								<ListItem className={ clsx( { "closedDrawer": !this.state.open } ) } selected={ this.state.pageId === "users" } button key={ "users" }>
									<ListItemFlex><MenuIcon width={ 25 } height={ 25 } src={ this.state.pageId !== "users" ? AvatarIcon : ActiveAvatarIcon } alt="" style={{ marginRight: this.state.open ? 16 : 0 }}/>{ 
										this.state.open
										?
										i18n.t( "menu_4" ) 
										:
										null
									}</ListItemFlex>
								</ListItem>
							</Tooltip>
						</Link>
					</List>
					<DevContainer>
						<img src={ SonacomLogo } alt="Sonacom" width={ 62 }/>
					</DevContainer>
				</Drawer>
				<Content className={ clsx( { "contentShift": this.state.open } ) }>
					<div>
						<Routes>
							<Route path="benef-trends" element={ <MenuTrends changePage={ () => this.changePage( "benef-trends" ) }/> }/>
							<Route path="type-of-reqs" element={ <MenuRequests changePage={ () => this.changePage( "type-of-reqs" ) }/> }/>
							<Route path="users" element={ <MenuUsers changePage={ () => this.changePage( "users") }/> } />
							<Route path="proc-times" element={ <MenuReqProc changePage={ () => this.changePage( "proc-times" ) }/> }/>
							<Route path="*" element={ <Navigate to="/data/proc-times" replace/> } />
						</Routes>
					</div>
				</Content>
			</Root>
		);
	}
}

export default withTranslation()( Dashboard );