import React from "react";
import { withStyles } from "@mui/styles";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import ReactECharts from "echarts-for-react";
import i18n from "../translation/settings";
import http from "../utils/http";
import clsx from "clsx";
import { convertDateFromIsoToFormat } from "../utils/functions";
import { api } from "../api";

import SearchIcon from "../assets/images/Search.svg";
import CrossIcon from "../assets/images/Cross.svg";

import * as Styled from "../assets/styles/Styled/Common";

const ChartDownloadIcon = {
	icon: "path://M17.166 11.146v2.03a3.688 3.688 0 0 1-3.671 3.689H4.688A3.688 3.688 0 0 1 1 13.176v-2.03M9 1v10.5m-4.312-4L9 11.812l4.495-4.495",
	iconStyle: {
		borderColor: "#0078FF",
		borderWidth: 2,
		borderCap: "round"
	}
}

const classes = ( theme ) => ( {
	footer: {
		height: 300
	},
	filters: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center"
	},
	filterPeriod: {
		marginTop: 50,
		display: "flex",
		flexDirection: "row",
		gap: 20,
		justifyContent: "center",
		alignItems: "center"
	},
	filterCities: {
		display: "flex",
		width: 774,
		flexWrap: "wrap",
		justifyContent: "center",
		marginTop: 20,
		gap: 10
	}
} );

class MenuTrends extends React.Component {

	constructor( props ) {
		super( props );
		const periodEndDefault = new Date();
		const periodStartDefault = ( new Date( periodEndDefault.getFullYear(), periodEndDefault.getMonth(), 1 ) );
		// const periodStartDefault = ( new Date( periodEndDefault.getFullYear(), 0, 1 ) );
		this.state = {
			loading: true,

			periodStart: periodStartDefault.toISOString(),
			periodEnd: periodEndDefault.toISOString(),

			cityHalls: [],
			cityHallsSelected: [],

			timeSeriesState: [],
			beneficiaryState: [],
		}
		this.props.changePage();
	}

	componentDidMount = async () => {
		this.setState( { loading: true } );
		const accessToken = localStorage.getItem( "token" );

		const periodStart = this.state.periodStart !== null
			? convertDateFromIsoToFormat( this.state.periodStart, "DD.MM.YYYY" )
			: "";
		const periodEnd = this.state.periodEnd !== null
			? convertDateFromIsoToFormat( this.state.periodEnd, "DD.MM.YYYY" )
			: "";

		let GetCityHalls
		let cityHalls
		let PostAllTimeSeriesData
		let PostAllBeneficiaryData;
		
		try{
			GetCityHalls =
				await http.get(
					api.dashboard.getCityHalls(),
					{ headers: { "Authorization": `Bearer ${ accessToken }` } }
				);
			cityHalls =
				await GetCityHalls.data.map( ( cityHall, index ) => {
					return cityHall.code;
				} );
			PostAllTimeSeriesData = 
				await http.post(
					api.dashboard.postTimeSeriesData(),
					{
						"city_halls_codes": cityHalls,
						"period_start": periodStart,
						"period_end": periodEnd
					},
					{ headers: { "Authorization": `Bearer ${ accessToken }` } }
				);
			PostAllBeneficiaryData =
				await http.post(
					api.dashboard.postBeneficiaryData(),
					{
						"city_halls_codes": cityHalls,
						"period_start": periodStart,
						"period_end": periodEnd
					},
					{ headers: { "Authorization": `Bearer ${ accessToken }` } }
				);
		} catch( e ) {
			//alert( "Error happened! Call developer!" );
		}
		this.setState(
			{
				cityHalls: GetCityHalls.data,
				cityHallsSelected: cityHalls,
				timeSeriesState: PostAllTimeSeriesData.data,
				beneficiaryState: PostAllBeneficiaryData.data,
				loading: false
			}
		);
	}
	componentWillUnmount = () => {
		// bad hacky solution
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = ( state, callback ) => {
			return;
		};
	}

	getData = async () => {
		this.setState( { loading: true } );
		
		const accessToken = localStorage.getItem( "token" );

		const periodStart = this.state.periodStart !== null
			? convertDateFromIsoToFormat( this.state.periodStart, "DD.MM.YYYY" )
			: "";
		const periodEnd = this.state.periodEnd !== null
			? convertDateFromIsoToFormat( this.state.periodEnd, "DD.MM.YYYY" )
			: "";
		const PostAllTimeSeriesData = 
			await http.post(
				api.dashboard.postTimeSeriesData(),
				{
					"city_halls_codes": this.state.cityHallsSelected,
					"period_start": periodStart,
					"period_end": periodEnd
				},
				{ headers: { "Authorization": `Bearer ${ accessToken }` } }
			);
		const PostAllBeneficiaryData =
			await http.post(
				api.dashboard.postBeneficiaryData(),
				{
					"city_halls_codes": this.state.cityHallsSelected,
					"period_start": periodStart,
					"period_end": periodEnd
				},
				{ headers: { "Authorization": `Bearer ${ accessToken }` } }
			);
		this.setState(
			{
				timeSeriesState: PostAllTimeSeriesData.data,
				beneficiaryState: PostAllBeneficiaryData.data,
				loading: false
			}
		);
	}

	periodStartChange = ( date ) => {
		if ( date instanceof Date && !isNaN( date ) ) {
			this.setState({periodStart: new Date( date ).toISOString() } );
			return;
		}
		this.setState( { periodStart: null } );
	}
	periodEndChange = ( date ) => {
		if ( date instanceof Date && !isNaN( date ) ) {
			this.setState( { periodEnd: new Date( date ).toISOString() } );
			return;
		}
		this.setState( { periodEnd: null } );
	}
	cityHallSelectedChange = ( cityHall ) => () => {
		if( this.state.cityHallsSelected.includes( cityHall ) ) {
			const newCityHallsSelected = this.state.cityHallsSelected.filter( e => e !== cityHall );
			this.setState( { cityHallsSelected: newCityHallsSelected } );
		} else {
			const newCityHallsSelected = this.state.cityHallsSelected;
			newCityHallsSelected.push( cityHall );
			this.setState( { cityHallsSelected: newCityHallsSelected } );
		}
	}
	cityHallsDeselectAll = () => {
		if( this.state.cityHalls.length > 0 && this.state.cityHallsSelected.length !== 0 )
			this.setState( { cityHallsSelected: [] } );
	}

	getTimeSeries( statisticsByCityHall, monthValues ) {
		let series = [];
		for (const key in statisticsByCityHall) {
			let cityHallData = [];

			for (var i = 0; i < monthValues.length; i++) {
				const currentMonth = monthValues[i];
				let monthDataInCityHall = statisticsByCityHall[key].find(element => element.MONTH === currentMonth);
				if (typeof monthDataInCityHall === 'undefined') {
					cityHallData.push(null);
				} else {
					cityHallData.push(monthDataInCityHall.TOTAL_REQUESTS);
				}
			}

			let seriesEntry = {
				name: key,
				type: 'line',
				stack: false,
				data: cityHallData
			};

			series.push(seriesEntry);
		}

		series.sort((a, b) => {
			return a.name.localeCompare(b.name);
		})

		return series;
	}
	optionNumberOfTotalReqPerMonth = () => {
		let cityHallNames = [];
		let monthValues = [];
		let statisticsByCityHall = {};
		let timeSeriesStateTemp = [...this.state.timeSeriesState];
		const groupByField = "CITY_HALL_NAME";
		
		timeSeriesStateTemp.forEach(instance => {
			cityHallNames.push(instance.CITY_HALL_NAME);
			cityHallNames = [...new Set(cityHallNames)]; //only unique entries, o/w duplicates
			cityHallNames.sort( (a, b) => {
				return a.localeCompare(b);
			})
			
			// if no such month
			if (monthValues.filter((elm) => {
				return elm.MONTH === instance.MONTH;
			}).length <= 0) {
				monthValues.push({MONTH: instance.MONTH, MONTH_NAME: instance.MONTH_NAME});
			}
			monthValues.sort( (a, b) => {
				return a.MONTH.localeCompare(b.MONTH);
			} );
			
			const currentlyGroupedField = instance[groupByField];
			if (!statisticsByCityHall[currentlyGroupedField]) {
				statisticsByCityHall[currentlyGroupedField] = [];
			}
			statisticsByCityHall[currentlyGroupedField].push(instance);
		});

		const option = {
			title: {
				text: i18n.t('chart_1'),
				left: "center",
				textStyle: {
					fontSize: "18",
					fontFamily: "Rubik",
					fontWeight: 500,
					color: "#212121"
				}
			},
			tooltip: {trigger: "axis"},
			legend: {
				data: cityHallNames, 
				x: "center", 
				y: "40px",
				textStyle: {
					color: "#212121",
					fontFamily: "Rubik",
					fontSize: 16
				}
			},
			grid: {top: "25%", containLabel: true},
			toolbox: {
				padding: [0, 40, 0, 0],
				right: "auto",
				feature: {
					// dataZoom: { yAxisIndex: 'none' },
					saveAsImage: ChartDownloadIcon
				}
			},
			xAxis: {
				type: 'category',
				data: monthValues.map(value => {
					return {
						value: value.MONTH_NAME, 
						textStyle: {
							color: "#212121",
							fontFamily: "Rubik",
							fontSize: 16
						}
					}
				} ),
				name: i18n.t('chart_1_param_2'),
				boundaryGap: false,
				nameTextStyle: { 
					color: "#212121",
					fontFamily: "Rubik",
					fontSize: 16
				},
			},
			yAxis: { 
				type: 'value', 
				boundaryGap: false, 
				name: i18n.t('chart_1_param_1'),
				nameTextStyle: { 
					color: "#212121",
					fontFamily: "Rubik",
					fontSize: 16
				},
			},
			dataZoom: [
				{type: 'inside', start: 0, end: 100},
				{start: 0, end: 100}
			],
			series: this.getTimeSeries(statisticsByCityHall, monthValues.map(value => value.MONTH))
		};
		return option;
	}

	calcTotal = ( series ) => {
		return (param) => {
			let sum = 0;
			series.forEach(item => {
				sum += item.data[param.dataIndex];
			});
			return sum
		}
	}
	getRequestsFromBeneficiariesChartLabel = ( beneficiaryData ) => {
		let beneficiaryDataPoints = beneficiaryData.map(Number);
		let beneficiaryDataSum = beneficiaryDataPoints.reduce((partialSum, a) => partialSum + a, 0);
		let beneficiariesChartLabel = '(' + beneficiaryDataSum + ')';
		return beneficiariesChartLabel;
	}
	optionNumberOfTotalReqByBefType = () => {
		let cityHallNames = [];
		let citizenBeneficiaryData = [];
		let legalBeneficiaryData = [];
		let emailBeneficiaryData = [];

		this.state.beneficiaryState.forEach(instance => {
			cityHallNames.push( { 
				value: instance.CITY_HALL_NAME, 
				textStyle: {
					color: "#212121",
					fontFamily: "Rubik",
					fontSize: 16
				} 
			} );
			cityHallNames = [...new Set(cityHallNames)];

			citizenBeneficiaryData.push(instance.CITIZEN_BENF);
			legalBeneficiaryData.push(instance.LEGAL_BENF);
			emailBeneficiaryData.push(instance.EMAIL_BENF);
		});

		let citizenBeneficiaryLabel = i18n.t('chart_2_param_3') + " " + this.getRequestsFromBeneficiariesChartLabel(citizenBeneficiaryData);
		let legalBeneficiaryLabel = i18n.t('chart_2_param_4') + " " + this.getRequestsFromBeneficiariesChartLabel(legalBeneficiaryData);
		// let emailBeneficiaryLabel = 'from Email ' + this.getRequestsFromBeneficiariesChartLabel(emailBeneficiaryData);

		let citizenBeneficiarySeries = citizenBeneficiaryData.map(Number);
		let legalBeneficiarySeries = legalBeneficiaryData.map(Number);
		// let emailBeneficiarySeries = emailBeneficiaryData.map(Number);
		// emailBeneficiarySeries = emailBeneficiarySeries.map(value => value === 0 ? null : value );
		let initialBeneficiaryTotalSeries = new Array(citizenBeneficiarySeries.length).fill(0);

		const option = {
			title: {
				text: i18n.t('chart_2'),
				left: "center",
				textStyle: {
					fontSize: "18",
					fontFamily: "Rubik",
					fontWeight: 500,
					color: "#212121"
				}
			},
			legend: {
				// data: [citizenBeneficiaryLabel, legalBeneficiaryLabel, emailBeneficiaryLabel],
				data: [citizenBeneficiaryLabel, legalBeneficiaryLabel],
				padding: [25, 0, 0, 0],
				textStyle: {
					color: "#212121",
					fontFamily: "Rubik",
					fontSize: 16
				}
			},
			grid: {left: '5%', right: '10%', bottom: '10%', containLabel: true},
			toolbox: {padding: [0, 40, 0, 0], feature: { saveAsImage: ChartDownloadIcon }},
			xAxis: [
				{
					autoSkip: false,
					type: 'category',
					data: cityHallNames,
					name: i18n.t('chart_2_param_2'),
					nameTextStyle: { 
						color: "#212121",
						fontFamily: "Rubik",
						fontSize: 16
					},
					axisLabel: {
						show: true,
						interval: 0,
						rotate: 90 //If label names are too long you can rotate them
					}
				}
			],
			yAxis: [
				{
					type: 'value',
					name: i18n.t('chart_2_param_1'),
					nameTextStyle: { 
						color: "#212121",
						fontFamily: "Rubik",
						fontSize: 16
					}
				}
			],
			series: [
				{
					name: citizenBeneficiaryLabel,
					type: 'bar',
					stack: true,
					label: {
						show: true
					},
					emphasis: {
						focus: 'series'
					},
					data: citizenBeneficiarySeries,
					barCategoryGap: "5%",
					// barMinHeight: 20
				},
				{
					name: legalBeneficiaryLabel,
					type: 'bar',
					stack: true,
					label: {
						show: true
					},
					emphasis: {
						focus: 'series'
					},
					data: legalBeneficiarySeries,
					barCategoryGap: "5%",
					// barMinHeight: 20
				},
				// {
				//   name: emailBeneficiaryLabel,
				//   type: 'bar',
				//   stack: true,
				//   label: {
				//     show: true
				//   },
				//   emphasis: {
				//     focus: 'series'
				//   },
				//   data: emailBeneficiarySeries
				// },
				{
					name: 'Total per City Hall',
					type: 'bar',
					stack: true,
					label: {
						show: true,
						position: 'top',
						formatter: this.calcTotal([
							{data: citizenBeneficiarySeries},
							{data: legalBeneficiarySeries}
							// {data: emailBeneficiarySeries}
						]), //Total Beneficiaries per City Hall
						color: "black",
						fontSize: 15
					},
					emphasis: {focus: 'series'},
					data: initialBeneficiaryTotalSeries,
					barCategoryGap: "5%"
				}
			]
		};
		return option;
	}

	getUniqueBeneficiariesChartLabel = ( beneficiaryData ) => {
		let beneficiaryDataPoints = beneficiaryData.map( Number );
		let beneficiaryDataSum = beneficiaryDataPoints.reduce( ( partialSum, a ) => partialSum + a, 0 );
		let beneficiariesChartLabel = '(' + beneficiaryDataSum + ')';
		return beneficiariesChartLabel;
	}
	optionNumberOfUniqueBefByType = () => {
		let cityHallNames = [];
		let citizenBeneficiaryData = [];
		let legalBeneficiaryData = [];
		let emailBeneficiaryData = [];

		this.state.beneficiaryState.forEach(instance => {
			cityHallNames.push( { 
				value: instance.CITY_HALL_NAME, 
				textStyle: {
					color: "#212121",
					fontFamily: "Rubik",
					fontSize: 16
				} 
			} );
			cityHallNames = [...new Set(cityHallNames)];

			citizenBeneficiaryData.push(instance.CITIZEN_BENF_UNIQUE);
			legalBeneficiaryData.push(instance.LEGAL_BENF_UNIQUE);
			emailBeneficiaryData.push(instance.EMAIL_BENF_UNIQUE);
		});

		let citizenBeneficiaryLabel = i18n.t('chart_3_param_3') + " " + this.getUniqueBeneficiariesChartLabel(citizenBeneficiaryData);
		let legalBeneficiaryLabel = i18n.t('chart_3_param_4') + " " + this.getUniqueBeneficiariesChartLabel(legalBeneficiaryData);
		// let emailBeneficiaryLabel = 'unique Email ' + this.getUniqueBeneficiariesChartLabel(emailBeneficiaryData);

		let citizenBeneficiarySeries = citizenBeneficiaryData.map(Number);
		let legalBeneficiarySeries = legalBeneficiaryData.map(Number);
		// let emailBeneficiarySeries = emailBeneficiaryData.map(Number);
		// emailBeneficiarySeries = emailBeneficiarySeries.map(value => value === 0 ? null : value );
		let initialBeneficiaryTotalSeries = new Array(citizenBeneficiarySeries.length).fill(0);

		const option = {
			title: {
				text: i18n.t('chart_3'),
				left: "center",
				textStyle: {
					fontSize: "18",
					fontFamily: "Rubik",
					fontWeight: 500,
					color: "#212121"
				}
			},
			legend: {
				// data: [citizenBeneficiaryLabel, legalBeneficiaryLabel, emailBeneficiaryLabel],
				data: [citizenBeneficiaryLabel, legalBeneficiaryLabel],
				padding: [25, 0, 0, 0],
				textStyle: {
					color: "#212121",
					fontFamily: "Rubik",
					fontSize: 16
				}
			},
			grid: {
				left: '5%',
				right: '10%',
				bottom: '10%',
				containLabel: true
			},
			toolbox: {
				padding: [0, 40, 0, 0],
				feature: {
					saveAsImage: ChartDownloadIcon
				}
			},
			xAxis: [
				{
					type: 'category',
					data: cityHallNames,
					name: i18n.t('chart_3_param_2'),
					nameTextStyle: { 
						color: "#212121",
						fontFamily: "Rubik",
						fontSize: 16
					},
					axisLabel: {
						show: true,
						interval: 0,
						rotate: 90 //If label names are too long you can rotate them
					}
				}
			],
			yAxis: [
				{
					type: 'value',
					name: i18n.t('chart_3_param_1'),
					nameTextStyle: { 
						color: "#212121",
						fontFamily: "Rubik",
						fontSize: 16
					}
				}
			],
			series: [
				{
					name: citizenBeneficiaryLabel,
					type: 'bar',
					stack: true,
					label: {show: true},
					emphasis: {focus: 'series'},
					data: citizenBeneficiarySeries,
					barCategoryGap: "5%",
					// barMinHeight: 20
				},
				{
					name: legalBeneficiaryLabel,
					type: 'bar',
					stack: true,
					label: {show: true},
					emphasis: {focus: 'series'},
					data: legalBeneficiarySeries,
					barCategoryGap: "5%",
					// barMinHeight: 20
				},
				// {
				//   name: emailBeneficiaryLabel,
				//   type: 'bar',
				//   stack: true,
				//   label: {
				//     show: true
				//   },
				//   emphasis: {
				//     focus: 'series'
				//   },
				//   data: emailBeneficiarySeries
				// },
				{
					name: 'Total per City Hall',
					type: 'bar',
					stack: true,
					label: {
						show: true,
						formatter: this.calcTotal([
							{data: citizenBeneficiarySeries},
							{data: legalBeneficiarySeries}
							// {data: emailBeneficiarySeries}
						]), //Total Beneficiaries per City Hall
						position: 'top',
						color: "black",
						fontSize: 15
					},
					emphasis: {
						focus: 'series'
					},
					data: initialBeneficiaryTotalSeries,
					barCategoryGap: "5%"
				}
			]
		};
		return option;
	}

	optionGenderDistributionFizic = () => {
		let cityHallNames = [];
		let femaleBeneficiaryPercents = [];
		let maleBeneficiaryPercents = [];

		this.state.beneficiaryState.forEach(instance => {
			cityHallNames.push( { 
				value: instance.CITY_HALL_NAME, 
				textStyle: {
					color: "#212121",
					fontFamily: "Rubik",
					fontSize: 16
				} 
			} );
			cityHallNames = [...new Set(cityHallNames)];

			femaleBeneficiaryPercents.push((instance.FEMALE_BENF_PERCENT / instance.CITIZEN_BENF_UNIQUE * 100).toFixed(1));
			maleBeneficiaryPercents.push((instance.MALE_BENF_PERCENT / instance.CITIZEN_BENF_UNIQUE * 100).toFixed(1));
		});

		const option = {
			title: {
				text: i18n.t('chart_4'),
				left: "center",
				textStyle: {
					fontSize: "18",
					fontFamily: "Rubik",
					fontWeight: 500,
					color: "#212121"
				}
			},
			legend: {
				data: [i18n.t('chart_4_param_3'), i18n.t('chart_4_param_4')],
				padding: [35, 0, 0, 0],
				textStyle: {
					color: "#212121",
					fontFamily: "Rubik",
					fontSize: 16
				}
			},
			grid: {
				left: '5%',
				right: '10%',
				bottom: '10%',
				containLabel: true
			},
			toolbox: {
				padding: [0, 40, 0, 0],
				feature: {
					saveAsImage: ChartDownloadIcon
				}
			},
			xAxis: [
				{
					type: 'category',
					data: cityHallNames,
					name: i18n.t('chart_4_param_2'),
					nameTextStyle: { 
						color: "#212121",
						fontFamily: "Rubik",
						fontSize: 16
					},
					axisLabel: {
						show: true,
						interval: 0,
						rotate: 90 //If label names are too long you can rotate them
					}
				}
			],
			yAxis: [
				{
					type: 'value',
					name: i18n.t('chart_4_param_1'),
					nameTextStyle: { 
						color: "#212121",
						fontFamily: "Rubik",
						fontSize: 16
					}
				}
			],
			series: [
				{
					name: i18n.t('chart_4_param_3'),
					type: 'bar',
					stack: true,
					label: {
						show: true,
						formatter: '{c}%'
					},
					emphasis: {
						focus: 'series'
					},
					data: femaleBeneficiaryPercents
				},
				{
					name: i18n.t('chart_4_param_4'),
					type: 'bar',
					stack: true,
					label: {
						show: true,
						formatter: '{c}%'
					},
					emphasis: {
						focus: 'series'
					},
					data: maleBeneficiaryPercents
				}
			]
		};
		return option;
	}
	optionGenderDistributionEmail = () => {
		let cityHallNames = [];
		let femaleBeneficiaryPercents = [];
		let maleBeneficiaryPercents = [];
		let unknownBeneficiaryPercents = [];

		this.state.beneficiaryState.forEach( instance => {
			cityHallNames.push( { 
				value: instance.CITY_HALL_NAME, 
				textStyle: {
					color: "#212121",
					fontFamily: "Rubik",
					fontSize: 16
				} 
			} );
			cityHallNames = [...new Set(cityHallNames)];

			let unknownEmail = instance.EMAIL_BENF_UNIQUE - instance.EMAIL_FEMALE_BENF_PERCENT - instance.EMAIL_MALE_BENF_PERCENT;
			femaleBeneficiaryPercents.push((instance.EMAIL_FEMALE_BENF_PERCENT / instance.EMAIL_BENF_UNIQUE * 100).toFixed(1));
			maleBeneficiaryPercents.push((instance.EMAIL_MALE_BENF_PERCENT / instance.EMAIL_BENF_UNIQUE * 100).toFixed(1));
			unknownBeneficiaryPercents.push((unknownEmail / instance.EMAIL_BENF_UNIQUE * 100).toFixed(1));
		} );

		const option = {
			title: {
				text: i18n.t('chart_10'),
				left: "center",
				textStyle: {
					fontSize: "18",
					fontFamily: "Rubik",
					fontWeight: 500,
					color: "#212121"
				}
			},
			legend: {
				data: [i18n.t('chart_10_param_1'), i18n.t('chart_10_param_2'), i18n.t('chart_10_param_3')],
				padding: [35, 0, 0, 0],
				textStyle: {
					color: "#212121",
					fontFamily: "Rubik",
					fontSize: 16
				}
			},
			grid: {
				left: '5%',
				right: '10%',
				bottom: '10%',
				containLabel: true
			},
			toolbox: {
				padding: [0, 40, 0, 0],
				feature: {
					saveAsImage: ChartDownloadIcon
				}
			},
			xAxis: [
				{
					type: 'category',
					data: cityHallNames,
					name: i18n.t('chart_10_param_4'),
					nameTextStyle: { 
						color: "#212121",
						fontFamily: "Rubik",
						fontSize: 16
					},
					axisLabel: {
						show: true,
						interval: 0,
						rotate: 90 //If label names are too long you can rotate them
					}
				}
			],
			yAxis: [
				{
					type: 'value',
					name: i18n.t('chart_10_param_5'),
					nameTextStyle: { 
						color: "#212121",
						fontFamily: "Rubik",
						fontSize: 16
					}
				}
			],
			series: [
				{
					name: i18n.t('chart_10_param_1'),
					type: 'bar',
					stack: true,
					label: {
						show: true,
						formatter: '{c}%'
					},
					emphasis: {
						focus: 'series'
					},
					data: femaleBeneficiaryPercents
				},
				{
					name: i18n.t('chart_10_param_2'),
					type: 'bar',
					stack: true,
					label: {
						show: true,
						formatter: '{c}%'
					},
					emphasis: {
						focus: 'series'
					},
					data: maleBeneficiaryPercents
				},
				{
					name: i18n.t('chart_10_param_3'),
					type: 'bar',
					stack: true,
					label: {
						show: true,
						formatter: '{c}%'
					},
					emphasis: {
						focus: 'series'
					},
					data: unknownBeneficiaryPercents
				}
			]
		};
		return option;
	}

	optionAvgNumOfReqPerMonth = () => {
		let cityHallNames = [];
		let avgBeneficiariesPerMonth = [];

		this.state.beneficiaryState.forEach(instance => {
			cityHallNames.push( { 
				value: instance.CITY_HALL_NAME, 
				textStyle: {
					color: "#212121",
					fontFamily: "Rubik",
					fontSize: 16
				} 
			} );
			cityHallNames = [...new Set(cityHallNames)];

			avgBeneficiariesPerMonth.push(instance.MONTH_AVG_REQUESTS);
		});

		const option = {
			title: {
				text: i18n.t('chart_5'),
				left: "center",
				textStyle: {
					fontSize: "18",
					fontFamily: "Rubik",
					fontWeight: 500,
					color: "#212121"
				}
			},
			grid: {
				left: '5%',
				right: '10%',
				bottom: '10%',
				containLabel: true
			},
			toolbox: {
				padding: [0, 40, 0, 0],
				feature: {
					saveAsImage: ChartDownloadIcon
				}
			},
			xAxis: {
				type: 'category',
				data: cityHallNames,
				name: i18n.t('chart_5_param_2'),
				nameTextStyle: { 
					color: "#212121",
					fontFamily: "Rubik",
					fontSize: 16
				},
				axisLabel: {
					show: true,
					interval: 0,
					rotate: 90 //If label names are too long you can rotate them
				}
			},
			yAxis: {
				type: 'value',
				name: i18n.t('chart_5_param_1'),
				nameTextStyle: { 
					color: "#212121",
					fontFamily: "Rubik",
					fontSize: 16
				}
			},
			series: [
				{
					type: 'bar',
					label: {
						show: true
					},
					data: avgBeneficiariesPerMonth
				}
			]
		};
		return option;
	}

	getAvgBeneficiariesChartOption = () => {
		let cityHallNames = [];
		let avgBeneficiariesPerMonth = [];

		this.state.beneficiaryState.forEach(instance => {
			cityHallNames.push( { 
				value: instance.CITY_HALL_NAME, 
				textStyle: {
					color: "#212121",
					fontFamily: "Rubik",
					fontSize: 16
				} 
			} );
			cityHallNames = [...new Set(cityHallNames)];

			avgBeneficiariesPerMonth.push(instance.MONTH_AVG_BENF);
		});

		const option = {
			title: {
				text: i18n.t('chart_6'),
				left: "center",
				textStyle: {
					fontSize: "18",
					fontFamily: "Rubik",
					fontWeight: 500,
					color: "#212121"
				}
			},
			grid: {
				left: '5%',
				right: '10%',
				bottom: '10%',
				containLabel: true
			},
			toolbox: {
				padding: [0, 40, 0, 0],
				feature: {
					saveAsImage: ChartDownloadIcon
				}
			},
			xAxis: {
				type: 'category',
				data: cityHallNames,
				name: i18n.t('chart_6_param_2'),
				nameTextStyle: { 
					color: "#212121",
					fontFamily: "Rubik",
					fontSize: 16
				},
				axisLabel: {
					show: true,
					interval: 0,
					rotate: 90 //If label names are too long you can rotate them
				}
			},
			yAxis: {
				type: 'value',
				name: i18n.t('chart_6_param_1'),
				nameTextStyle: { 
					color: "#212121",
					fontFamily: "Rubik",
					fontSize: 16
				}
			},
			series: [
				{
					type: 'bar',
					label: {
						show: true
					},
					data: avgBeneficiariesPerMonth
				}
			]
		};
		return option;
	}

	render() {
		return (
			<div>
				<div className={ this.props.classes.filters }>
					<div className={ this.props.classes.filterCities }>
						{
							this.state.cityHalls.map( ( value, index ) => {
								return (
									<Styled.Chip
										className={ `${ clsx( { "selected": this.state.cityHallsSelected.includes( value.code ) } ) }` }
										key={ value.code }
										label={ value.name }
										onClick={ this.cityHallSelectedChange( value.code ) }
									/>
								);
							} )
						}
						{
							this.state.cityHalls.length > 0
							?
							<Styled.BtnDeselect
								className={ `${ clsx( { "active": this.state.cityHallsSelected.length !== 0 } ) }` }
								key={ "deselect_button" }
								onClick={ this.cityHallsDeselectAll }
							>
								<img src={ CrossIcon } alt="x" width={ 12 }/>
								{ i18n.t( "btn_deselect" ) }
							</Styled.BtnDeselect>
							:
							<></>
						}
					</div>
					<div className={ this.props.classes.filterPeriod }>
						<LocalizationProvider dateAdapter={ AdapterDateFns }>
							<DatePicker
								PaperProps={{ 
									sx: { 
										"& .MuiTypography-root": {
											fontSize: 18
										},
										"& .MuiButtonBase-root": {
											"&.MuiPickersDay-root": {
												fontSize: 18,
												"&.Mui-selected": { 
													color: "#FFF", backgroundColor: "#0078FF" 
												}
											} 
										} 
									} 
								}}
								allowSameDateSelection
								label={ i18n.t( "filter_periodStart" ) }
								mask="__.__.____"
								inputFormat="dd.MM.yyyy"
								value={ i18n.t( "filter_periodStart" ) + "\n" + this.state.periodStart !== null ? this.state.periodStart : null }
								onChange={ this.periodStartChange }
								renderInput={ ( props ) => ( <Styled.PickerField {...props} /> ) }
							/>
						</LocalizationProvider>
						<LocalizationProvider dateAdapter={ AdapterDateFns }>
							<DatePicker
								PaperProps={{ 
									sx: { 
										"& .MuiTypography-root": {
											fontSize: 18
										},
										"& .MuiButtonBase-root": {
											"&.MuiPickersDay-root": {
												fontSize: 18,
												"&.Mui-selected": { 
													color: "#FFF", backgroundColor: "#0078FF" 
												}
											} 
										} 
									} 
								}}
								allowSameDateSelection
								label={ i18n.t( "filter_periodEnd" ) }
								mask="__.__.____"
								inputFormat="dd.MM.yyyy"
								value={ this.state.periodEnd !== null ? this.state.periodEnd : null }
								onChange={ this.periodEndChange }
								renderInput={ ( props ) => ( <Styled.PickerField {...props} /> ) }
							/>
						</LocalizationProvider>
						<Styled.Button
							disabled={ this.state.loading }
							variant="outlined"
							onClick={ this.getData }
						>
							<img src={ SearchIcon } alt=""/>
							<span>{ this.state.loading ? i18n.t( "btn_loading" ) : i18n.t( "btn_search" ) }</span>
						</Styled.Button>
					</div>
				</div>
				<Styled.Divider/>
				<Styled.ElementContainer>
					<ReactECharts option={ this.optionNumberOfTotalReqPerMonth() } notMerge={true} style={ { height: 600 } }/>
				</Styled.ElementContainer>
				<Styled.Divider/>
				<Styled.ElementContainer>
					<ReactECharts option={ this.optionNumberOfTotalReqByBefType() } style={ { height: 600 } }/>
				</Styled.ElementContainer>
				<Styled.Divider/>
				<Styled.ElementContainer>
					<ReactECharts option={ this.optionNumberOfUniqueBefByType() } style={ { height: 600 } }/>
				</Styled.ElementContainer>
				<Styled.Divider/>
				<Styled.ElementContainer>
					<ReactECharts option={ this.optionGenderDistributionFizic() } style={ { height: 600 } }/>
				</Styled.ElementContainer>
				<Styled.Divider/>
				<Styled.ElementContainer>
					<ReactECharts option={ this.optionGenderDistributionEmail() } style={ { height: 600 } }/>
				</Styled.ElementContainer>
				<Styled.Divider/>
				<Styled.ElementContainer>
					<ReactECharts option={ this.optionAvgNumOfReqPerMonth() } style={ { height: 600 } }/>
				</Styled.ElementContainer>
				<Styled.Divider/>
				<Styled.ElementContainer>
					<ReactECharts option={ this.getAvgBeneficiariesChartOption() } style={ { height: 600 } }/>
				</Styled.ElementContainer>
				<div className={ this.props.classes.footer }/>
			</div>
		);
	}
}
export default withStyles( classes ) ( MenuTrends );