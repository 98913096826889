import React, { Component } from "react";
import { withStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import MuiTextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import http from "../utils/http";
import { api } from "../api";
import logo from "../assets/images/LoginLogo.svg";
import DevBySonacomLogo from "../assets/images/DevBySonacom.svg";

const TextField = styled( MuiTextField )( () => ( {
	marginTop: 25, width: 289,
	fontFamily: "Rubik", fontWeight: 400, fontStyle: "italic", fontSize: 20,
	"& .Mui-focused": {
		"& label": {
			color: "#FFF"
		}
	},
	"& .MuiInputBase-root": {
		color: "#FFF",
		borderBottom: "2px solid #FFF",
		"&:before": {
			content: "none",
		},
		"&:after": {
			content: "none"
		},
	},
	"& input:focus": {
		color: "#FFF"
	},
	"& input": {
		fontStyle: "initial",
		fontSize: 18,
		"&::selection": {
			backgroundColor: "#2E3236"
		},
		"&:-webkit-autofill": {
			WebkitBoxShadow: "0 0 0 1000px #0078FF inset",
			WebkitTextFillColor: "#FFF"
		}
	},
	"& label": {
		color: "#FFF",
		"&.Mui-focused": {
			color: "#FFF"
		}
	}
} ) );

const classes = ( theme ) => ( {
	root: {
		backgroundColor: "#0078FF",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		height: "100%", width: "100%",
		"& .Mui-focused": { color: "#2c3956" },
		"& .MuiOutlinedInput-input": {
			paddingLeft: 15,
			borderRadius: 6,
			height: 51,
			paddingTop: 0,
			paddingBottom: 0,
			backgroundColor: "white"
		},
		"& .MuiOutlinedInput-root" : {
			height: 51,
			// "& fieldset" : { borderRadius: 6, borderColor: '#21abee' },
			"&:hover fieldset": { border: "2px solid #21abee" },
			"&.Mui-focused fieldset": { border: "2px solid #21abee" }
		},
	},
	dialog: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		width: 400,
		height: 300,
		borderRadius: 15
	},
	textfield: { marginTop: 25, width: 289 },
	button: {
		fontFamily: "Rubik", fontWeight: 400, fontSize: 20,
		marginTop: 54, width: 300, backgroundColor: "#2E3236", color: "white",
		borderRadius: 16, padding: "16px 0",
		border: "2px solid #0078FF",
		"&:hover": { backgroundColor: "#0078FF", border: "2px solid #FFF" },
		"& .MuiButton-label": { textTransform: "uppercase", color: "white", fontSize: 16 },
	},
	logo: { marginBottom: 30 },
	flashMessage: {
		position: "absolute",
		display: "flex",
		flexDirection: "row-reverse",
		alignItems: "center",
		width: "100%",
		top: 0,
		height: 51,
		backgroundColor: "#fd000b"
	},
	flashMessageText: { marginRight: 20, color: "white", fontSize: 15, marginLeft: 20 }
} );

class Login extends Component {
	constructor( props ) {
		super( props );
		this.state = {
			username : "",
			password : ""
		}
		document.title = "Cancelaria Dashboard Login";
	}
	
	login = () => { 
		if( this.state.username === "" || this.state.password === "" ) {
			return;
		}
		const url = api.authenticate();
		const data = {
			username: this.state.username,
			password: this.state.password
		};
		http.post( url, data, { responseType : "json" } )
		.then( ( response ) => {
			this.props.login( response.data.user.username, response.data.user.token );
		} )
		.catch( ( error ) => {
			//alert( error.response.data.message );
		} );
	}

	changeUserName = ( event ) => {
		this.setState( { username: event.target.value } );
	}
	changePassword = ( event ) => {
		this.setState( { password: event.target.value } );
	}

	catchEnterLogin = ( event ) => {
		if( this.state.username === "" || this.state.password === "" ) {
			return;
		}
		if( event.key === "Enter" ) {
			this.login();
		}
	}

	render() {
		return (
			<div className={ this.props.classes.root }>
				{
					this.props.loginError 
					? 
						<div className= { this.props.classes.flashMessage }>
							<div className= { this.props.classes.flashMessageText }>{ this.props.loginError }</div>
						</div>
					: null
				}
				<form className= { this.props.classes.dialog }>
					<img alt={ "App logo" } className={ this.props.classes.logo } src={ logo } width={ 290 }/>
					<TextField
						// sx={{ color: "red" }}
						autoComplete="username"
						label="Name" 
						variant="standard"
						onChange = { this.changeUserName }
						InputLabelProps={{ shrink: true }}
					/>
					<TextField
						label="Password"
						type="password"
						autoComplete="current-password"
						variant="standard"
						onChange = { this.changePassword }
						onKeyPress={this.catchEnterLogin}
						InputLabelProps={ { shrink : true } }
					/>
					<div style={ { marginTop: 20, textAlign: "center" } }>
						<Button className={ this.props.classes.button } onClick= { this.login }>Login</Button>
					</div>

					<div style={{ position: "absolute", marginTop: "43%" }}>
						<img src={ DevBySonacomLogo } alt="Dev"/>
					</div>
				</form>

			</div>
		);
	}
}
export default withStyles( classes )( Login );