import React from "react";
import { withStyles } from "@mui/styles";
import http from "../utils/http";
import { api } from "../api";
import i18n from "../translation/settings";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { exportCSVUTF16, convertDateFromIsoToFormat } from "../utils/functions";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

import ExportIcon from "../assets/images/Download.svg";
import SearchIcon from "../assets/images/Search.svg";

import * as Styled from "../assets/styles/Styled/Common";

const classes = ( theme ) => ( {
	theadMain: { display: "flex", flexDirection: "row", justifyContent: "center", height: 40 },
	theadText: { fontFamily: "Rubik", fontWeight: 500, color: "#212121", fontSize: 18 },
	theadImg: { height: 17, width: 16, marginLeft: 20, cursor: "pointer" }
} );

class MenuReqProc extends React.Component {

	constructor( props ) {
		super( props );
		const periodEndDefault = new Date();
		const periodStartDefault = ( new Date( periodEndDefault.getFullYear(), periodEndDefault.getMonth(), 1 ) );
		// const periodStartDefault = ( new Date( periodEndDefault.getFullYear(), 0, 1 ) );
		this.state = {
			periodStart: periodStartDefault.toISOString(),
			periodEnd: periodEndDefault.toISOString(),
			
			loading: true,
			
			cityHall: "",
			cityHalls: [],
			cityHallsSelected: [],

			serviceAvgProcessingTime: [],
			inTimeServicesStats: []
		}
		this.props.changePage();
	}

	componentDidMount = async () => {
		this.setState( { loading: true } );

		const accessToken = localStorage.getItem( "token" );

		let GetCityHalls;
		let cityHalls;
		try{
			GetCityHalls =
				await http.get(
					api.dashboard.getCityHalls(),
					{ headers: { "Authorization": `Bearer ${ accessToken }` } }
				);
			cityHalls =
				await GetCityHalls.data.map( ( cityHall, index ) => {
					return cityHall.code;
				} );
		} catch( e ) {
			//alert( "Error happened! Call developer!" );
		}

		this.setState(
			{
				cityHalls: GetCityHalls.data,
				cityHallsSelected: cityHalls,
				loading: false
			}
		);
		
	}
	componentWillUnmount = () => {
		// bad hacky solution
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = ( state, callback ) => {
			return;
		};
	}

	periodStartChange = ( date ) => {
		if ( date instanceof Date && !isNaN( date ) ) {
			this.setState({periodStart: new Date( date ).toISOString() } );
			return;
		}
		this.setState( { periodStart: null } );
	}
	periodEndChange = ( date ) => {
		if ( date instanceof Date && !isNaN( date ) ) {
			this.setState( { periodEnd: new Date( date ).toISOString() } );
			return;
		}
		this.setState( { periodEnd: null } );
	}
	cityHallChange = ( event ) => {
		this.setState( { cityHall: event.target.value } );
	}

	getData = async () => {
		if( this.state.cityHall === "" ) {
			return;
		}

		this.setState( { loading: true } );

		const accessToken = localStorage.getItem( "token" );

		const periodStart = this.state.periodStart !== null
			? convertDateFromIsoToFormat( this.state.periodStart, "DD.MM.YYYY" )
			: "";
		const periodEnd = this.state.periodEnd !== null
			? convertDateFromIsoToFormat( this.state.periodEnd, "DD.MM.YYYY" )
			: "";

		const postServiceAvgProcessingTime =
			await http.post(
				api.dashboard.postServiceAvgProcessingTime(),
				{
					"city_hall_code": this.state.cityHall,
					"period_start": periodStart,
					"period_end": periodEnd
				},
				{ headers: { "Authorization": `Bearer ${ accessToken }` } }
			);
		const postInTimeServicesStats =
			await http.post(
				api.dashboard.postInTimeServicesStats(),
				{
					"city_hall_code": this.state.cityHall,
					"period_start": periodStart,
					"period_end": periodEnd
				},
				{ headers: { "Authorization": `Bearer ${ accessToken }` } }
			);
		this.setState(
			{
				serviceAvgProcessingTime: postServiceAvgProcessingTime.data,
				inTimeServicesStats: postInTimeServicesStats.data,
				loading: false
			}
		);
	}

	exportServiceAvgProcessingTime = () => {
		const headerRow = [ 
			i18n.t( "table_2_head_1" ),
			i18n.t( "table_2_head_2" ),
			i18n.t( "table_2_head_3" ),
			i18n.t( "table_2_head_4" ),
			i18n.t( "table_2_head_5" ),
			i18n.t( "table_2_head_6" )
		];

		let rows = [];
		rows.push( headerRow );
		this.state.serviceAvgProcessingTime.forEach( ( data, index ) => {
			// const avgHours = Number.parseFloat( data.AVG_PROCESSING_HOURS ).toFixed( 2 );
			const avgHours = Math.round( data.AVG_PROCESSING_HOURS );
			// const avgDays = ( avgHours / 24 ).toFixed( 2 );
			const avgDays = Math.round( avgHours / 24 );
			// const maxDays = Number.parseFloat( data.PROCEDURE_DURATION ) / 24;
			
			let maxDays = "";
			if( parseInt( data.PROCEDURE_DURATION ) !== 0 ) {
				maxDays = Math.round( data.PROCEDURE_DURATION / 24 );
			}
			let maxHours = "";
			if( parseInt( data.PROCEDURE_DURATION ) !== 0 ) {
				maxHours = data.PROCEDURE_DURATION;
			}

			const row = [
				data.DOCUMENT_TEMPLATE_NAME,
				avgHours,
				avgDays,
				maxHours,
				maxDays,
				data.ROWS
			];
			rows.push( row );
		} );

		exportCSVUTF16( i18n.t( "table_2" ), rows );
	}
	exportIntimeServicesStats = () => {
		const headerRow = [
			i18n.t( "table_3_head_1" ),
			i18n.t( "table_3_head_2" ),
			i18n.t( "table_3_head_3" ),
			i18n.t( "table_3_head_4" ),
			i18n.t( "table_3_head_5" ),
			i18n.t( "table_3_head_6" )
		];

		let rows = [];
		rows.push( headerRow );
		this.state.inTimeServicesStats.forEach( ( data, index ) => {
			const rowsInTime = parseInt( data.ROWS_INTIME );
			const rowsNotInTime = parseInt( data.ROWS_NOT_INTIME );
			const rowsAll = parseInt( data.ROWS_ALL );
			const _rowsInTime = ( rowsInTime / rowsAll * 100 ).toFixed( 2 ) + "%";
			const _rowsNotInTime = ( rowsNotInTime / rowsAll * 100 ).toFixed( 2 ) + "%";
			let row = [
				data.DOCUMENT_TEMPLATE_NAME,
				_rowsInTime,
				rowsInTime,
				_rowsNotInTime,
				rowsNotInTime,
				rowsAll
			];
			rows.push( row );
		} );

		exportCSVUTF16( i18n.t( "table_3" ), rows );
	}

	renderServiceAvgProcessingTime = () => {
		return (
			<div>
				<div className={ this.props.classes.theadMain }>
					<div className={ this.props.classes.theadText }>{ i18n.t( "table_2" ) }</div>
					<img
						className={ this.props.classes.theadImg }
						alt={ "Export" }
						src={ ExportIcon }
						onClick={ this.exportServiceAvgProcessingTime }
					/>
				</div>
				<Styled.Troot>
					<Table padding={ "none" }>
						<Styled.Thead>
							<TableRow>
								<TableCell key={ "_dtn" } style={ { paddingLeft: 20, paddingRight: 20, minWidth: 120, maxWidth: 120, textAlign: "center" } }>{ i18n.t( "table_2_head_1" ) }</TableCell>
								<TableCell key={ "_avgph" } style={ { paddingLeft: 20, paddingRight: 20, minWidth: 120, maxWidth: 120, textAlign: "center" } }>{ i18n.t( "table_2_head_2" ) }</TableCell>
								<TableCell key={ "_avgpd" } style={ { paddingLeft: 20, paddingRight: 20, minWidth: 120, maxWidth: 120, textAlign: "center" } }>{ i18n.t( "table_2_head_3" ) }</TableCell>
								<TableCell key={ "_prdur" } style={ { paddingLeft: 20, paddingRight: 20, minWidth: 120, maxWidth: 120, textAlign: "center" } }>{ i18n.t( "table_2_head_4" ) }</TableCell>
								<TableCell key={ "_prdud" } style={ { paddingLeft: 20, paddingRight: 20, minWidth: 120, maxWidth: 120, textAlign: "center" } }>{ i18n.t( "table_2_head_5" ) }</TableCell>
								<TableCell key={ "_rows" } style={ { paddingLeft: 20, paddingRight: 20, minWidth: 120, maxWidth: 120, textAlign: "center" } }>{ i18n.t( "table_2_head_6" ) }</TableCell>
							</TableRow>
						</Styled.Thead>
						{ <TableBody>
							{ this.state.serviceAvgProcessingTime.map( ( row, index ) => {
								const avgHours = Math.round( row.AVG_PROCESSING_HOURS );
								const avgDays = Math.round( avgHours / 24 );
								
								let templateName = "-";
								if( row.DOCUMENT_TEMPLATE_NAME !== "" ) {
									templateName = row.DOCUMENT_TEMPLATE_NAME;
								}
								let maxDays = "-";
								if( parseInt( row.PROCEDURE_DURATION ) !== 0 ) {
									maxDays = Math.round( row.PROCEDURE_DURATION / 24 );
								}
								let maxHours = "-";
								if( parseInt( row.PROCEDURE_DURATION ) !== 0 ) {
									maxHours = row.PROCEDURE_DURATION;
								}
								return (
									<Styled.TableRow
										key={ index }
										role="checkbox"
									>
										<TableCell key={ index + "_dtn" } style={ { minWidth: 120, maxWidth: 120, textAlign: "center" } }>{ templateName }</TableCell>
										<TableCell key={ index + "_avgph" } style={ { minWidth: 120, maxWidth: 120, textAlign: "center" } }>{ avgHours }</TableCell>
										<TableCell key={ index + "_avgpd" } style={ { minWidth: 120, maxWidth: 120, textAlign: "center" } }>{ avgDays }</TableCell>
										<TableCell key={ index + "_prdur" } style={ { minWidth: 120, maxWidth: 120, textAlign: "center" } }>{ maxHours }</TableCell>
										<TableCell key={ index + "_prdud" } style={ { minWidth: 120, maxWidth: 120, textAlign: "center" } }>{ maxDays }</TableCell>
										<TableCell key={ index + "_rows" } style={ { minWidth: 120, maxWidth: 120, textAlign: "center" } }>{ row.ROWS }</TableCell>
									</Styled.TableRow>
								);
							} ) }
						</TableBody> }
					</Table>
				</Styled.Troot>
			</div>
		);
	}
	renderIntimeServicesStats = () => {
		return (
			<div>
				<div className={ this.props.classes.theadMain }>
					<div className={ this.props.classes.theadText }>{ i18n.t( "table_3" ) }</div>
					<img
						className={ this.props.classes.theadImg }
						alt={ "Export" }
						src={ ExportIcon }
						onClick={ this.exportIntimeServicesStats }
					/>
				</div>
				<Styled.Troot>
					<Table padding={ "none" }>
						<Styled.Thead>
							<TableRow>
								<TableCell key={ "__reqtp" } style={ { paddingLeft: 20, paddingRight: 20, minWidth: 200, maxWidth: 200, textAlign: "center" } }>{ i18n.t( "table_3_head_1" ) }</TableCell>
								<TableCell key={ "_avgphp" } style={ { paddingLeft: 20, paddingRight: 20, minWidth: 100, maxWidth: 100, textAlign: "center" } }>{ i18n.t( "table_3_head_2" ) }</TableCell>
								<TableCell key={ "_avgphr" } style={ { paddingLeft: 20, paddingRight: 20, minWidth: 120, maxWidth: 120, textAlign: "center" } }>{ i18n.t( "table_3_head_3" ) }</TableCell>
								<TableCell key={ "_prdurp" } style={ { paddingLeft: 20, paddingRight: 20, minWidth: 120, maxWidth: 120, textAlign: "center" } }>{ i18n.t( "table_3_head_4" ) }</TableCell>
								<TableCell key={ "_prdurr" } style={ { paddingLeft: 20, paddingRight: 20, minWidth: 120, maxWidth: 120, textAlign: "center" } }>{ i18n.t( "table_3_head_5" ) }</TableCell>
								<TableCell key={ "_rows" } style={ { paddingLeft: 20, paddingRight: 20, minWidth: 120, maxWidth: 120, textAlign: "center" } }>{ i18n.t( "table_3_head_6" ) }</TableCell>
							</TableRow>
						</Styled.Thead>
						{ <TableBody>
							{ this.state.inTimeServicesStats.map( ( row, index ) => {

								let templateName = "-";
								if( row.DOCUMENT_TEMPLATE_NAME !== "" ) {
									templateName = row.DOCUMENT_TEMPLATE_NAME;
								}

								const rowsInTime = parseInt( row.ROWS_INTIME );
								const rowsNotInTime = parseInt( row.ROWS_NOT_INTIME );
								const rowsAll = parseInt( row.ROWS_ALL );
								const _rowsInTime = ( rowsInTime / rowsAll * 100 ).toFixed( 2 );
								const _rowsNotInTime = ( rowsNotInTime / rowsAll * 100 ).toFixed( 2 );
								return (
									<Styled.TableRow
										key={ index }
										role="checkbox"
									>
										<TableCell key={ index + "_reqtp" } style={ { minWidth: 200, maxWidth: 200, textAlign: "center" } }>{ templateName }</TableCell>
										<TableCell key={ index + "_rit" } style={ { minWidth: 100, maxWidth: 100, textAlign: "center" } }>{ _rowsInTime }</TableCell>
										<TableCell key={ index + "__rit" } style={ { minWidth: 120, maxWidth: 120, textAlign: "center" } }>{ rowsInTime }</TableCell>
										<TableCell key={ index + "_rnit" } style={ { minWidth: 120, maxWidth: 120, textAlign: "center" } }>{ _rowsNotInTime }</TableCell>
										<TableCell key={ index + "__rnit" } style={ { minWidth: 120, maxWidth: 120, textAlign: "center" } }>{ rowsNotInTime }</TableCell>
										<TableCell key={ index + "_ra" } style={ { minWidth: 120, maxWidth: 120, textAlign: "center" } }>{ rowsAll }</TableCell>
									</Styled.TableRow>
								);
							} ) }
						</TableBody> }
					</Table>
				</Styled.Troot>
			</div>
		);
	}
	render() {
		return (
			<div>
				<div style={{ height: 120, display: "flex", justifyContent: "center", alignItems: "end" }}>
					<Styled.FilterPeriod>
						<LocalizationProvider dateAdapter={ AdapterDateFns }>
							<DatePicker
								PaperProps={{ 
									sx: { 
										"& .MuiTypography-root": {
											fontSize: 18
										},
										"& .MuiButtonBase-root": {
											"&.MuiPickersDay-root": {
												fontSize: 18,
												"&.Mui-selected": { 
													color: "#FFF", backgroundColor: "#0078FF" 
												}
											} 
										} 
									} 
								}}
								allowSameDateSelection
								label={ i18n.t( "filter_periodStart" ) }
								mask="__.__.____"
								inputFormat="dd.MM.yyyy"
								value={ this.state.periodStart !== null ? this.state.periodStart : null }
								onChange={ this.periodStartChange }
								renderInput={ ( props ) => ( <Styled.PickerField {...props} /> ) }
							/>
						</LocalizationProvider>
						<LocalizationProvider dateAdapter={ AdapterDateFns }>
							<DatePicker
								PaperProps={{ 
									sx: { 
										"& .MuiTypography-root": {
											fontSize: 18
										},
										"& .MuiButtonBase-root": {
											"&.MuiPickersDay-root": {
												fontSize: 18,
												"&.Mui-selected": { 
													color: "#FFF", backgroundColor: "#0078FF" 
												}
											} 
										} 
									} 
								}}
								allowSameDateSelection
								label={ i18n.t( "filter_periodEnd" ) }
								mask="__.__.____"
								inputFormat="dd.MM.yyyy"
								value={ this.state.periodEnd !== null ? this.state.periodEnd : null }
								onChange={ this.periodEndChange }
								renderInput={ ( props ) => ( <Styled.PickerField { ...props }/> ) }
							/>
						</LocalizationProvider>
						<Box sx={ { minWidth: 200 } }>
							<FormControl fullWidth>
								<Styled.PickerField
									select
									id="cityhall-select"
									value={ this.state.cityHall }
									label={ i18n.t( "filter_cityHall" ) }
									onChange={ this.cityHallChange }
								>
									{ this.state.cityHalls.map( ( cityHall, index ) => {
										return (
											<MenuItem
												key={ cityHall.code }
												value={ cityHall.code }
											>{ cityHall.name }</MenuItem>
										);
									} ) }
								</Styled.PickerField>
							</FormControl>
						</Box>
						<Styled.Button
							disabled={ this.state.loading }
							variant="outlined"
							onClick={ this.getData }
						>
							<img src={ SearchIcon } alt=""/>
							<span>{ this.state.loading ? i18n.t( "btn_loading" ) : i18n.t( "btn_search" ) }</span>
						</Styled.Button>
					</Styled.FilterPeriod>
				</div>
				<Styled.Divider/>
				<Styled.ElementContainer>
					{ this.renderServiceAvgProcessingTime() }
				</Styled.ElementContainer>
				<Styled.Divider/>
				<Styled.ElementContainer>
					{ this.renderIntimeServicesStats() }
				</Styled.ElementContainer>
				<Styled.Footer/>
			</div>
		);
	}
}
export default withStyles( classes ) ( MenuReqProc );