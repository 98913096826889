import axios from "axios";

const http = axios.create( { withCredentials: true } );
http.interceptors.response.use( ( response ) => response, ( error ) => {
	if( error.response.status === 401 ) {
		localStorage.setItem( "isLogined", "0" );
		localStorage.setItem( "token", "" );
		localStorage.setItem( "userName", "" );
		window.location.reload();
	} else {
		return Promise.reject( error );
	}
} );
export default http;