import React from "react";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import GlobeIcon from "../assets/images/Globe.svg";
import ActiveLangIcon from "../assets/images/ActiveLang.svg";

const LanguageSelector = styled( 'div' )( () => ( {
	display: "flex", alignItems: "center", gap: 10,
	width: 108,
	fontFamily: "Rubik", fontWeight: 500, fontSize: 16,
	letterSpacing: "-0.03em",
	marginLeft: 'auto', marginRight: 20,
	cursor: "pointer",
	userSelect: "none"
} ) );
const LangText = styled( 'div' )( () => ( {
	display: "flex",
	color: "#212121",
	"& > img": {
		marginBottom: 3
	},
	"& > div": {
		fontFamily: "Rubik", fontWeight: 500, fontSize: 16,
		letterSpacing: "-0.03em"
	}
} ) );

const DropdownContent = styled( 'div')( () => ( {
	fontFamily: "Rubik", fontWeight: 500, fontSize: 15,
	userSelect: "none",
	display: "none",
	position: "absolute",
	marginTop: 30,
	backgroundColor: "#FFF",
	marginLeft: 80,
	overflow: "auto",
	zIndex: 3,
	boxShadow: "0px 0px 16px #B1C4CD",
	borderRadius: 12,
	padding: "23px 0",
	"&.expand":{
		display: "block"
	},
	"& > a, & > div":{
		cursor: "pointer",
		fontSize: 14,
		color: "#2F3346",
		padding: "4px 0px",
		textDecoration: "none",
		display: "flex",
		"& > img": {
			padding: "0px 10px"
		}
	},
	"& img": {
		padding: "0 13px",
		"&.notActive": {
			filter: "invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%)"
		}
	}
} ) );

const DropDown = styled( 'div')( () => ( {
	width: "77%",
	display: "flex", justifyContent: "end"
} ) );

const Translate = () => {
	document.addEventListener("mousedown", clickOutsideAdd);

	const { i18n } = useTranslation();
	const [language, setLanguage] = React.useState("ro");

	const Languages = [
		{
			"ro": {
				name: "ROMÂNA"
			}
		},
		{
			"ru": {
				name: "РУССКИЙ"
			}
		},
		{
			"en": {
				name: "ENGLISH"
			}
		}
	]

	function changeLanguage( event ) {
		setLanguage( event.currentTarget.dataset.value );

		i18n.changeLanguage( event.currentTarget.dataset.value );
		addDropDownOpen();
	}
	
	function clickOutsideAdd( event ){
		let dropDown = document.getElementById("dropdownContent");
		if( dropDown === null )
			return;

		let box = dropDown.getBoundingClientRect();
		if( event.target.id === "myDropDown" ) {
			return;
		}
		if( event.pageX < box.left || event.pageX > box.left + dropDown.clientWidth ||
			event.pageY < box.top-30 || event.pageY > box.top + dropDown.clientHeight ) {
			dropDown.classList.remove( "expand" );
		}
	}
	function addDropDownOpen( event ){
		let dropDown = document.getElementById("dropdownContent");
		if( !dropDown.classList.contains( "expand" ) )
			dropDown.style.width = 24 + event.currentTarget.clientWidth + "px";

		dropDown.classList.toggle( "expand" );
	}

	return (
		<DropDown>
			<LanguageSelector id="myDropDown" onClick={ addDropDownOpen }>
				<img src={ GlobeIcon } alt=""/>
				{ i18n.t( "btn_lang" ) }
			</LanguageSelector>
			<DropdownContent id="dropdownContent">
			{
				Languages.map( (lang) => {
					return(
						<div key={ Object.keys(lang)[0] } data-value={ Object.keys(lang)[0] } onClick={ changeLanguage }>{
								Object.keys(lang)[0] === language
								?
								<LangText>
									<img src={ ActiveLangIcon } alt="*" width={ 8 }/>
									<div>{ lang[Object.keys(lang)[0]].name }</div>
								</LangText>
								:
								<LangText>
									<div style={{  marginLeft: 34 }}>{ lang[Object.keys(lang)[0]].name }</div>
								</LangText>
						}</div>
					)
				}) 
			}
			</DropdownContent>
		</DropDown>
	);
}

export default Translate;