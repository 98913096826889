import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use( initReactI18next ).init( {
	fallbackLng: "ro",
	lng: "ro",
	resources: {
		en: { translations: require( "./locales/en.json" ) },
		ro: { translations: require( "./locales/ro.json" ) },
		ru: { translations: require( "./locales/ru.json" ) }
	},
	ns: [ "translations" ],
	defaultNS: "translations"
} );

i18n.languages = [ "en", "ro", "ru" ];

export default i18n;