import React from "react";
import { withStyles } from "@mui/styles";
import ReactECharts from "echarts-for-react";
import i18n from "../translation/settings";
import http from "../utils/http";
import { api } from "../api";
import clsx from "clsx";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { TableHead } from "@mui/material";
import TableRow from "@mui/material/TableRow";

import ExportIcon from "../assets/images/Download.svg";
import SearchIcon from "../assets/images/Search.svg";
import CrossIcon from "../assets/images/Cross.svg";

import { exportCSVUTF16 } from "../utils/functions";

import * as Styled from "../assets/styles/Styled/Common";

const ChartDownloadIcon = {
	icon: "path://M17.166 11.146v2.03a3.688 3.688 0 0 1-3.671 3.689H4.688A3.688 3.688 0 0 1 1 13.176v-2.03M9 1v10.5m-4.312-4L9 11.812l4.495-4.495",
	iconStyle: {
		borderColor: "#0078FF",
		borderWidth: 2,
		borderCap: "round"
	}
}

const classes = ( theme ) => ( {
	footer: {
		height: 300
	},
	filters: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center"
	},
	filterPeriod: {
		height: 100,
		display: "flex",
		flexDirection: "row",
		gap: 20,
		justifyContent: "center",
		alignItems: "center"
	},
	filterCities: {
		display: "flex",
		width: 774,
		flexWrap: "wrap",
		justifyContent: "center",
		marginTop: 20,
		gap: 10
	},
	troot: {
		overflow: "auto",
		height: "100%",
		textTransform: "uppercase",
		border: "1px solid #D5DEE3",
		borderRadius: "12px 0 0 0",
		"& .MuiTableRow-head": {
			"& .MuiTableCell-head": {
				fontSize : 13,
				overflowWrap: "anywhere",
				fontFamily : "Rubik",
				fontWeight : 500,
				color: "black",
				borderRight: "1px solid #D5DEE3",
				borderBottom: "none",
				"&:last-of-type": {
					borderRight: "none"
				}
			}
		}
	},
	thead: {
		position: "sticky",
		top: 0,
		color: "black",
		height: 60,
		wordSpacing: 10,
		backgroundColor: "#fafafa",
	},
	table: { overflow: "auto", height: "100%", width: "100%" },
	tableRow: { height: 30 }
} );

class MenuUsers extends React.Component {

	constructor( props ) {
		super( props );

		this.state = {
			loading: true,
			cityHalls: [],
			cityHallsSelected: [],
			cityHallsRendered: [],
			activeUsersDistribution: [],
			activeUsers: []
		}
		this.props.changePage();
	}

	componentDidMount = async () => {
		this.setState( { loading: true } );
		const accessToken = localStorage.getItem( "token" );

		let GetCityHalls;
		let cityHalls;
		let PostActiveUsersDistribution;
		let PostActiveUsers;

		try{
			GetCityHalls = await http.get(
				api.dashboard.getCityHalls(),
				{ headers: { "Authorization": `Bearer ${ accessToken }` } }
			);
			cityHalls = await GetCityHalls.data.map( ( cityHall, index ) => {
				return cityHall.code;
			} );
			PostActiveUsersDistribution = await http.post(
				api.dashboard.postActiveUsersDistribution(),
				{
					"city_halls_codes": cityHalls,
				},
				{ headers: { "Authorization": `Bearer ${ accessToken }` } }
			);
			PostActiveUsers = await http.post(
				api.dashboard.postActiveUsers(),
				{
					"city_halls_codes": cityHalls,
				},
				{ headers: { "Authorization": `Bearer ${ accessToken }` } }
			);
		} catch( e ) {
			//alert( "Error happened! Call developer!" );
		}

		this.setState( {
			cityHalls: GetCityHalls.data,
			cityHallsSelected: cityHalls,
			cityHallsRendered: cityHalls,
			activeUsersDistribution: PostActiveUsersDistribution.data,
			activeUsers: PostActiveUsers.data,
			loading: false
		} );
	}
	componentWillUnmount = () => {
		// bad hacky solution
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = ( state, callback ) => {
			return;
		};
	}

	getData = async () => {
		this.setState( { loading: true } );
		const accessToken = localStorage.getItem( "token" );
		const PostActiveUsersDistribution = await http.post(
			api.dashboard.postActiveUsersDistribution(),
			{ "city_halls_codes": this.state.cityHallsSelected },
			{ headers: { "Authorization": `Bearer ${ accessToken }` } }
		);
		const PostActiveUsers = await http.post(
			api.dashboard.postActiveUsers(),
			{ "city_halls_codes": this.state.cityHallsSelected },
			{ headers: { "Authorization": `Bearer ${ accessToken }` } }
		);
		this.setState( {
			cityHallsRendered: this.state.cityHallsSelected,
			activeUsersDistribution: PostActiveUsersDistribution.data,
			activeUsers: PostActiveUsers.data,
			loading: false
		} );
	}

	cityHallSelectedChange = ( cityHall ) => () => {
		if( this.state.cityHallsSelected.includes( cityHall ) ) {
			const newCityHallsSelected = this.state.cityHallsSelected.filter( e => e !== cityHall );
			this.setState( { cityHallsSelected: newCityHallsSelected } );
		} else {
			const newCityHallsSelected = this.state.cityHallsSelected;
			newCityHallsSelected.push( cityHall );
			this.setState( { cityHallsSelected: newCityHallsSelected } );
		}
	}
	cityHallsDeselectAll = () => {
		this.setState( { cityHallsSelected: [] } );
	}

	getOperatorsChartData = ( cityHallNames, operatorsData ) => {
		let data = [];

		for (const key in cityHallNames) {
			let dataEntry = {
				value: operatorsData[key],
				name: cityHallNames[key]
			};

			data.push(dataEntry);
		}
		return data;
	}
	optionActiveUsersDistribution = () => {
		let cityHallNames = [];
		let operatorsData = [];

		this.state.activeUsersDistribution.forEach(instance => {
			cityHallNames.push(instance.CITY_HALL_NAME);
			operatorsData.push(instance.NUMBER_OF_OPERATORS);
		});

		// let numberOfOperators = operatorsData.map(Number);
		let chartData = this.getOperatorsChartData(cityHallNames, operatorsData);

		const option = {
			title: {
				text: i18n.t( "chart_8" ),
				left: "center",
				textStyle: {
					fontSize: "18",
					fontFamily: "Rubik",
					fontWeight: 500,
					color: "#212121"
				}
			},
			tooltip: {
				trigger: 'item'
			},
			legend: {
				top: '6%',
				left: 'center',
				textStyle: {
					color: "#212121",
					fontFamily: "Rubik",
					fontSize: 16
				}
			},
			grid: {
				left: '5%',
				right: '5%',
				bottom: '10%',
				containLabel: true
			},
			toolbox: {
				padding: [0, 40, 0, 0],
				feature: {
					saveAsImage: ChartDownloadIcon
				}
			},
			series: [
				{
					name: i18n.t( "chart_8_param_1" ),
					type: 'pie',
					radius: ['40%', '70%'],
					avoidLabelOverlap: false,
					itemStyle: {
						borderRadius: 10,
						borderColor: '#fff',
						borderWidth: 2
					},
					label: {
						show: true,
						normal: {
							formatter: '{c} ' + i18n.t( "chart_8_param_2" ) + ' \n {d}%',
							position: 'inside'
						}
					},
					data: chartData
				}
			]
		};
		return option;
	}

	exportActiveUsers = () => {
		let ch = {};
		this.state.cityHalls.forEach( ( row, index ) => {
			if( this.state.cityHallsRendered.includes( row.code ) ) {
				ch[row.code] = row.name;
			}
		} );

		let rows = [];
		let headerRow = [ i18n.t( "table_4_head_1" ) ];
		this.state.cityHallsRendered.forEach( ( header, index ) => {
			headerRow.push( ch[header] );
		} );
		rows.push( headerRow );

		Object.keys( this.state.activeUsers ).forEach( ( rsLevel ) => {
			let row = [ rsLevel ];
			const levelData = this.state.activeUsers[rsLevel];
			this.state.cityHallsRendered.forEach( ( cityHallCode ) => {
				let cell = "";
				if( levelData[cityHallCode] !== undefined ) {
					levelData[cityHallCode].forEach( ( v, i ) => {
						cell += v.specialityName + ": " + v.rows + "."
					} );
				}
				row.push( cell );
			} );
			rows.push( row );
		} )
		exportCSVUTF16( i18n.t( "table_4" ), rows );
	}

	renderRequestTypesTable = () => {
		let ch = {};
		this.state.cityHalls.forEach( ( row, index ) => {
			if( this.state.cityHallsRendered.includes( row.code ) ) {
				ch[row.code] = row.name;
			}
		} );
		return (
			<>
				<div style={{ display: "flex", flexDirection: "row", justifyContent: "center", height: 40 }}>
					<div style={{ fontFamily: "Rubik", fontWeight: 500, color: "#2E3236", fontSize: 18 }}>{ i18n.t( "table_1" ) }</div>
					<img style={{ height: 17, width: 16, marginLeft: 20, cursor: "pointer" }} alt={ "Export" } src={ ExportIcon } onClick={ this.exportRequestTypes }/>
				</div>
				<div className={ this.props.classes.troot }>
					<Table padding={ "none" }>
						<TableHead className={ this.props.classes.thead }>
							<TableRow key={ "hr_" }>
								<TableCell
									key={ "_type" }
									style={ { minWidth: 120, maxWidth: 120, textAlign: "center", height: 60 } }
								>{ i18n.t( "table_1_head_1" ) }</TableCell>
								{ this.state.cityHallsRendered.map( ( value, index ) => {
									return (
										<TableCell
											key={ "_hc_" + index }
											style={ { minWidth: 40, maxWidth: 40, textAlign: "center", height: 60 } }
										>{ ch[value] + " %(#)" }</TableCell>
									);
								} ) }
							</TableRow>
						</TableHead>
						{
							<TableBody>
								{ this.state.requestTypes.map( ( row, index ) => {
									return (
										<TableRow
											key={ "br_" + index }
											role="checkbox"
											className={ this.props.classes.tableRow }
										>
											<TableCell key={ "_bc1_" + index } style={{ maxWidth: 120,  minWidth: 120, textAlign: "center", height: 60, textTransform: "uppercase" }}>{ row.type }</TableCell>
											{ this.state.cityHallsRendered.map( ( cityHall, index ) => {
												let cell = "-";
												if( parseInt( row.data[cityHall] ) !== 0 ) {
													cell =
														( row.data[cityHall]["selection"] / row.data[cityHall]["total"] * 100 ).toFixed( 2 ) + "%"
														+ " ("
														+ row.data[cityHall]["selection"]
														+ "/"
														+ row.data[cityHall]["total"]
														+ ")";
												}
												return (
													<TableCell key={ "_bc2_" + index } style={{ maxWidth: 40,  minWidth: 40, textAlign: "center", height: 60, wordSpacing: 9999999 }}>{ cell }</TableCell>
												);
											} ) }
										</TableRow>
									);
								} ) }
							</TableBody>
						}
					</Table>
				</div>
			</>
		);
	}
	renderActiveUsersTable = () => {
		let ch = {};
		this.state.cityHalls.forEach( ( row, index ) => {
			if( this.state.cityHallsRendered.includes( row.code ) ) {
				ch[row.code] = row.name;
			}
		} );
		return (
			<>
				<div style={{ display: "flex", flexDirection: "row", justifyContent: "center", height: 40 }}>
					<div style={{ fontFamily: "Rubik", fontWeight: 500, color: "#212121", fontSize: 18 }}>{ i18n.t( "table_4" ) }</div>
					<img style={{ height: 17, width: 16, marginLeft: 20, cursor: "pointer" }} alt={ "Export" } src={ ExportIcon } onClick={ this.exportActiveUsers }/>
				</div>
				<Styled.Troot>
					<Table padding={ "none" }>
						<Styled.Thead>
							<TableRow key={ "hr_" }>
								<TableCell
									key={ "_type" }
									style={ { minWidth: 100, maxWidth: 100, textAlign: "center", height: 60, color: "#0078FF" } }
								>{ i18n.t( "table_4_head_1" ) }</TableCell>
								{ this.state.cityHallsRendered.map( ( value, index ) => {
									return (
										<TableCell
											key={ "_hc_" + index }
											style={ { minWidth: 200, maxWidth: 200, textAlign: "center", height: 60 } }
										>{ ch[value] }</TableCell>
									);
								} ) }
							</TableRow>
						</Styled.Thead>
						<TableBody>
							{ Object.keys( this.state.activeUsers ).map( ( rsLevel ) => {
								const levelData = this.state.activeUsers[rsLevel];
								return (
									<Styled.TableRow
										key={ "rsLevel-" + rsLevel }
										role="checkbox"
									>
										<TableCell key={ "rsSpecialLevel-" + rsLevel } style={{ maxWidth: 100, minWidth: 100, textAlign: "center" }}>{ rsLevel }</TableCell>
										{ this.state.cityHallsRendered.map( ( cityHallCode ) => {
											if(levelData[cityHallCode] === undefined ) {
												return (
													<TableCell
														key={ "cell-" + cityHallCode + "-" + rsLevel }
														style={{ maxWidth: 200,  minWidth: 200, fontWeight: "normal" }}
													></TableCell>
												);
											}
											let cell = "";
											levelData[cityHallCode].forEach( ( v, i ) => { cell += v.specialityName + ": " + v.rows + ";\r\n" } );
											return (
												<TableCell
													key={ "cell-" + cityHallCode + "-" + rsLevel }
													style={{ maxWidth: 200,  minWidth: 200, whiteSpace: "pre-line", fontWeight: "normal", textAlign: "center" }}
												>
													{ cell }
												</TableCell>
											);
										} ) }
									</Styled.TableRow>
								);
							} ) }
						</TableBody>
					</Table>
				</Styled.Troot>
			</>
		);
	}
	render() {
		return (
			<div>
				<Styled.Filters>
					<Styled.FilterCities>
						{
							this.state.cityHalls.map( ( value, index ) => {
								return (
									<Styled.Chip
										className={ `${ clsx( { "selected": this.state.cityHallsSelected.includes( value.code ) } ) }` }
										key={ value.code }
										label={ value.name }
										onClick={ this.cityHallSelectedChange( value.code ) }
									/>
								);
							} )
						}
						{
							this.state.cityHalls.length > 0
							?
							<Styled.BtnDeselect
								className={ `${ clsx( { "active": this.state.cityHallsSelected.length !== 0 } ) }` }
								key={ "deselect_button" }
								onClick={ this.cityHallsDeselectAll }
							>
								<img src={ CrossIcon } alt="x" width={ 12 }/>
								{ i18n.t( "btn_deselect" ) }
							</Styled.BtnDeselect>
							:
							<></>
						}
					</Styled.FilterCities>
					<Styled.FilterPeriod>
						<Styled.Button
							disabled={ this.state.loading }
							variant="outlined"
							onClick={ this.getData }
						>
							<img src={ SearchIcon } alt=""/>
							<span>{ this.state.loading ? i18n.t( "btn_loading" ) : i18n.t( "btn_search" ) }</span>
						</Styled.Button>
					</Styled.FilterPeriod>
				</Styled.Filters>
				<Styled.Divider/>
				<Styled.ElementContainer>
					<ReactECharts option={ this.optionActiveUsersDistribution() } style={ { height: 600 } }/>
				</Styled.ElementContainer>
				<Styled.Divider/>
				<Styled.ElementContainer>
					{ this.renderActiveUsersTable() }
				</Styled.ElementContainer>
				<Styled.Footer/>
			</div>
		);
	}
}
export default withStyles( classes ) ( MenuUsers );