import React from "react";
import { withStyles } from "@mui/styles";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import ReactECharts from "echarts-for-react";
import i18n from "../translation/settings";
import http from "../utils/http";
import { api } from "../api";
import clsx from "clsx";

import {
	Table,
	TableBody,
	TableCell,
	TableRow,
	Box,
	CircularProgress 
} from "@mui/material";

import ExportIcon from "../assets/images/Download.svg";
import SearchIcon from "../assets/images/Search.svg";
import CrossIcon from "../assets/images/Cross.svg";

import { exportCSVUTF16, convertDateFromIsoToFormat } from "../utils/functions";

import DocumentTemplateRequests from "../components/DocumentTemplateRequests";
import * as Styled from "../assets/styles/Styled/Common";

const ChartDownloadIcon = {
	icon: "path://M17.166 11.146v2.03a3.688 3.688 0 0 1-3.671 3.689H4.688A3.688 3.688 0 0 1 1 13.176v-2.03M9 1v10.5m-4.312-4L9 11.812l4.495-4.495",
	iconStyle: {
		borderColor: "#0078FF",
		borderWidth: 2,
		borderCap: "round"
	}
}

const classes = ( theme ) => ( {

} );

class MenuRequests extends React.Component {

	constructor( props ) {
		super( props );
		const periodEndDefault = new Date();
		const periodStartDefault = ( new Date( periodEndDefault.getFullYear(), periodEndDefault.getMonth(), 1 ) );
		// const periodStartDefault = ( new Date( periodEndDefault.getFullYear(), 0, 1 ) );
		this.state = {
			loading: true,
			periodStart: periodStartDefault.toISOString(),
			periodEnd: periodEndDefault.toISOString(),
			cityHalls: [],
			cityHallsSelected: [],
			cityHallsRendered: [],
			timeSeriesState: [],
			processedRequests: [],
			requestTypes: [],

			docRequestsPeriodStart: periodStartDefault.toISOString(),
			docRequestsPeriodEnd: periodEndDefault.toISOString(),
			processedDocumentTemplates: [],
			cityHall: ""
		}
		this.props.changePage();
	}

	componentDidMount = async () => {
		await this.getCityHalls();
		await this.getProcessedRequests();
	}
	componentWillUnmount = () => {
		// bad hacky solution
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = ( state, callback ) => {
			return;
		};
	}

	getCityHalls = async () => {
		this.setState( { loading: true } );
		const accessToken = localStorage.getItem( "token" );

		const cityHalls = await http.get(
			api.dashboard.getCityHalls(),
			{ headers: { "Authorization": `Bearer ${ accessToken }` } }
		);
		const cityHallsCodes = await cityHalls.data.map( ( cityHall, index ) => {
			return cityHall.code;
		} );

		this.setState( {
			cityHalls: cityHalls.data,
			cityHallsSelected: cityHallsCodes,
			cityHallsRendered: cityHallsCodes,
			loading: false
		} );
	}

	getProcessedRequests = async () => {
		this.setState( { loading: true } );
		const accessToken = localStorage.getItem( "token" );
		const periodStart = this.state.periodStart !== null ? convertDateFromIsoToFormat( this.state.periodStart, "DD.MM.YYYY" ) : "";
		const periodEnd = this.state.periodEnd !== null ? convertDateFromIsoToFormat( this.state.periodEnd, "DD.MM.YYYY" ) : "";
		
		let PostProcessedRequests;
		let PostRequestTypeDataTable;

		try{
			// GetCityHalls = await http.get(
			// 	api.dashboard.getCityHalls(),
			// 	{ headers: { "Authorization": `Bearer ${ accessToken }` } }
			// );
			// cityHalls = await GetCityHalls.data.map( ( cityHall, index ) => {
			// 	return cityHall.code;
			// } );
			PostProcessedRequests = await http.post(
				api.dashboard.postProcessedRequests(),
				{
					"city_halls_codes": this.state.cityHallsSelected,
					"period_start": periodStart,
					"period_end": periodEnd
				},
				{ headers: { "Authorization": `Bearer ${ accessToken }` } }
			);
			PostRequestTypeDataTable = await http.post(
				api.dashboard.postRequestTypeDataTable(),
				{
					"city_halls_codes": this.state.cityHallsSelected,
					"period_start": periodStart,
					"period_end": periodEnd
				},
				{ headers: { "Authorization": `Bearer ${ accessToken }` } }
			);
		} catch( e ) {
			//alert( "Error happened! Call developer!" );
		}

		this.setState( {
			// cityHalls: GetCityHalls.data,
			// cityHallsSelected: cityHalls,
			// cityHallsRendered: cityHalls,
			processedRequests: PostProcessedRequests.data,
			requestTypes: PostRequestTypeDataTable.data,
			loading: false
		} );
	}

	getProcessedDocumentTemplates = async () => {
		this.setState( { loading: true } );
		const accessToken = localStorage.getItem( "token" );
		const periodStart = this.state.docRequestsPeriodStart !== null ? convertDateFromIsoToFormat( this.state.docRequestsPeriodStart, "DD.MM.YYYY" ) : "";
		const periodEnd = this.state.docRequestsPeriodEnd !== null ? convertDateFromIsoToFormat( this.state.docRequestsPeriodEnd, "DD.MM.YYYY" ) : "";

		const url = api.dashboard.postGetProcessedDocumentTemplates();
		const data = {
			"city_hall_code": this.state.cityHall,
			"period_start": periodStart,
			"period_end": periodEnd
		}
		const headers = { "Authorization": `Bearer ${ accessToken }` };

		try {
			const PostProcessedDocumentTemplates = await http.post( url, data, { headers } );

			console.log(PostProcessedDocumentTemplates.data)
	
			this.setState( {
				loading: false,
				processedDocumentTemplates: PostProcessedDocumentTemplates.data
			} );
		} catch ( error ) {
			console.log('error')

			this.setState( {
				loading: false,
			} );
		}
	}

	getData = async () => {
		this.setState( { loading: true } );
		const accessToken = localStorage.getItem( "token" );
		const periodStart = this.state.periodStart !== null ? convertDateFromIsoToFormat( this.state.periodStart, "DD.MM.YYYY" ) : "";
		const periodEnd = this.state.periodEnd !== null ? convertDateFromIsoToFormat( this.state.periodEnd, "DD.MM.YYYY" ) : "";
		const PostProcessedRequests = await http.post(
			api.dashboard.postProcessedRequests(),
			{
				"city_halls_codes": this.state.cityHallsSelected,
				"period_start": periodStart,
				"period_end": periodEnd
			},
			{ headers: { "Authorization": `Bearer ${ accessToken }` } }
		);
		const PostRequestTypeDataTable = await http.post(
			api.dashboard.postRequestTypeDataTable(),
			{
				"city_halls_codes": this.state.cityHallsSelected,
				"period_start": periodStart,
				"period_end": periodEnd
			},
			{ headers: { "Authorization": `Bearer ${ accessToken }` } }
		);
		this.setState( {
			cityHallsRendered: this.state.cityHallsSelected,
			processedRequests: PostProcessedRequests.data,
			requestTypes: PostRequestTypeDataTable.data,
			loading: false
		} );
	}

	periodStartChange = ( date ) => {
		if ( date instanceof Date && !isNaN( date ) ) {
			this.setState({periodStart: new Date( date ).toISOString() } );
			return;
		}
		this.setState( { periodStart: null } );
	}
	periodEndChange = ( date ) => {
		if ( date instanceof Date && !isNaN( date ) ) {
			this.setState( { periodEnd: new Date( date ).toISOString() } );
			return;
		}
		this.setState( { periodEnd: null } );
	}
	cityHallSelectedChange = ( cityHall ) => () => {
		if( this.state.cityHallsSelected.includes( cityHall ) ) {
			const newCityHallsSelected = this.state.cityHallsSelected.filter( e => e !== cityHall );
			this.setState( { cityHallsSelected: newCityHallsSelected } );
		} else {
			const newCityHallsSelected = this.state.cityHallsSelected;
			newCityHallsSelected.push( cityHall );
			this.setState( { cityHallsSelected: newCityHallsSelected } );
		}
	}
	cityHallsDeselectAll = () => {
		this.setState( { cityHallsSelected: [] } );
	}

	calcTotal = ( series ) => {
		return (param) => {
			let sum = 0;
			series.forEach(item => {
				sum += item.data[param.dataIndex];
			});
			return sum
		}
	}
	optionNumberOfProcessedReqs = () => {
		let cityHallNames = [];
		let processedRequestsData = [];
		let inProgressRequestsData = [];

		this.state.processedRequests.forEach(instance => {
			//Set name for data and Styling
			cityHallNames.push( { 
				value: instance.CITY_HALL_NAME, 
				textStyle: {
					color: "#212121",
					fontFamily: "Rubik",
					fontSize: 16
				} 
			} );
			cityHallNames = [...new Set(cityHallNames)];

			processedRequestsData.push(instance.PROCESSED_REQUESTS);
			inProgressRequestsData.push(instance.IN_PROGRESS_REQUESTS);
		});

		let processedRequestsSeries = processedRequestsData.map(Number);
		let inProgressRequestsSeries = inProgressRequestsData.map(Number);
		let initialTotalRequestsSeries = new Array(processedRequestsSeries.length).fill(0);

		const option = {
			title: {
				text: i18n.t( "chart_7" ),
				left: "center",
				textStyle: {
					fontSize: "18",
					fontFamily: "Rubik",
					fontWeight: 500,
					color: "#212121"
				}
			},
			legend: {
				data: [ i18n.t( "chart_7_param_3" ), i18n.t( "chart_7_param_4" ) ],
				x: 'right',
				padding: [35, 50, 0, 0],
				textStyle: {
					color: "#212121",
					fontFamily: "Rubik",
					fontSize: 16
				}
			},
			grid: {
				left: '5%',
				right: '10%',
				bottom: '10%',
				containLabel: true
			},
			toolbox: {
				padding: [0, 40, 0, 0],
				feature: {
					saveAsImage: ChartDownloadIcon
				}
			},
			xAxis: [
				{
					type: 'category',
					data: cityHallNames,
					name: i18n.t( "chart_7_param_2" ),
					nameTextStyle: { 
						color: "#212121",
						fontFamily: "Rubik",
						fontSize: 16
					},
					axisLabel: {
						show: true,
						interval: 0,
						rotate: 90 //If label names are too long you can rotate them
					}
				}
			],
			yAxis: [
				{
					type: 'value',
					name: i18n.t( "chart_7_param_1" ),
					nameTextStyle: { 
						color: "#212121",
						fontFamily: "Rubik",
						fontSize: 16
					}
				}
			],
			series: [
				{
					name: i18n.t( "chart_7_param_3" ),
					type: 'bar',
					stack: true,
					label: {
						show: true
					},
					emphasis: {
						focus: 'series'
					},
					data: processedRequestsSeries,
					barCategoryGap: "5%",
					// barMinHeight: 20
				},
				{
					name: i18n.t( "chart_7_param_4" ),
					type: 'bar',
					stack: true,
					label: {
						show: true
					},
					emphasis: {
						focus: 'series'
					},
					data: inProgressRequestsSeries,
					barCategoryGap: "5%",
					// barMinHeight: 20
				},
				{
					name: 'Total Requests',
					type: 'bar',
					stack: true,
					label: {
						show: true,
						formatter: this.calcTotal([{data: processedRequestsSeries}, {data: inProgressRequestsSeries}]),
						position: 'top',
						color: "black",
						fontSize: 15
					},
					emphasis: {
						focus: 'series'
					},
					data: initialTotalRequestsSeries
				}
			]
		};
		return option;
	}

	exportRequestTypes = () => {
		let ch = {};
		this.state.cityHalls.forEach( ( row, index ) => {
			if( this.state.cityHallsRendered.includes( row.code ) ) {
				ch[row.code] = row.name;
			}
		} );

		let rows = [];
		let headerRow = [ i18n.t( "table_1_head_1" ) ];
		this.state.cityHallsRendered.forEach( ( header, index ) => {
			headerRow.push( ch[header] );
		} );
		rows.push( headerRow );

		this.state.requestTypes.forEach( ( _row, index ) => {
			let row = [ _row.type ];
			this.state.cityHallsRendered.forEach( ( cityHall, index ) => {
				if( parseInt( _row.data[cityHall] ) === 0 ) {
					row.push( "-" );
				} else {
					let cell =
						( _row.data[cityHall]["selection"] / _row.data[cityHall]["total"] * 100 ).toFixed( 2 ) + "%"
						+ " ("
						+ _row.data[cityHall]["selection"]
						+ "/"
						+ _row.data[cityHall]["total"]
						+ ")";
					row.push( cell );
				}
			} );
			rows.push( row );
		} );

		exportCSVUTF16( i18n.t( "table_1" ), rows );
	}

	renderRequestTypesTable = () => {
		let ch = {};
		this.state.cityHalls.forEach( ( row, index ) => {
			if( this.state.cityHallsRendered.includes( row.code ) ) {
				ch[row.code] = row.name;
			}
		} );
		return (
			<>
				<div style={{ display: "flex", flexDirection: "row", justifyContent: "center", height: 60, marginTop: 10 }}>
					<div style={{ fontFamily: "Rubik", fontWeight: 500, color: "#212121", fontSize: 18 }}>{ i18n.t( "table_1" ) }</div>
					<img style={{ height: 17, width: 16, marginLeft: 20, cursor: "pointer" }} alt={ "Export" } src={ ExportIcon } onClick={ this.exportRequestTypes }/>
				</div>
				<Styled.Troot>
					<Table padding={ "none" }>
						<Styled.Thead>
							<TableRow key={ "hr_" }>
								<TableCell
									key={ "_type" }
									style={ { width: 80, textAlign: "center", height: 60, backgroundColor: "#FFF", color: "#0078FF", padding: "0 10px" } }
								>{ i18n.t( "table_1_head_1" ) }</TableCell>
								{ this.state.cityHallsRendered.map( ( value, index ) => {
									return (
										<TableCell
											key={ "_hc_" + index }
											style={ { minWidth: 40, maxWidth: 40, textAlign: "center", height: 60, backgroundColor: "#FFF" } }
										>{ ch[value] }<br/>{ "%(#)" }</TableCell>
									);
								} ) }
							</TableRow>
						</Styled.Thead>
						{
							<TableBody>
								{ this.state.requestTypes.map( ( row, index ) => {
									return (
										<Styled.TableRow
											key={ "br_" + index }
											role="checkbox"
										>
											<TableCell key={ "_bc1_" + index } style={{ width: 120, textAlign: "center", height: 60 }}>{ row.type }</TableCell>
											{ this.state.cityHallsRendered.map( ( cityHall, index ) => {
												let cell = "-";
												if( parseInt( row.data[cityHall] ) !== 0 && row.data[cityHall] !== undefined ) {
													cell =
														( row.data[cityHall]["selection"] / row.data[cityHall]["total"] * 100 ).toFixed( 2 ) + "%"
														+ " ("
														+ row.data[cityHall]["selection"]
														+ "/"
														+ row.data[cityHall]["total"]
														+ ")";
												}
												return (
													<TableCell key={ "_bc2_" + index } style={{ maxWidth: 40,  minWidth: 40, textAlign: "center", height: 60, wordSpacing: 9999999 }}>{ cell }</TableCell>
												);
											} ) }
										</Styled.TableRow>
									);
								} ) }
							</TableBody>
						}
					</Table>
				</Styled.Troot>
			</>
		);
	}

	scrollToTemplateRequests = () => {
		const templatesRequests = document.getElementById("treq");
		templatesRequests.scrollIntoView({
			block: "center",
			behavior: "smooth"
		});
	}

	render() {
		return (
			<div>
				{/* <Box gap={ 1 } padding={ "0 50px" }>
					<Styled.GotoBtn
						component={ "button" }
						onClick={ this.scrollToTemplateRequests }
					>Template requests</Styled.GotoBtn>
				</Box> */}

				<Styled.Filters>
					<Styled.FilterCities>
						{
							this.state.cityHalls.map( ( value, index ) => {
								return (
									<Styled.Chip
										className={ `${ clsx( { "selected": this.state.cityHallsSelected.includes( value.code ) } ) }` }
										key={ value.code }
										label={ value.name }
										onClick={ this.cityHallSelectedChange( value.code ) }
									/>
								);
							} )
						}
						{
							this.state.cityHalls.length > 0
							?
							<Styled.BtnDeselect
								className={ `${ clsx( { "active": this.state.cityHallsSelected.length !== 0 } ) }` }
								key={ "deselect_button" }
								onClick={ this.cityHallsDeselectAll }
							>
								<img src={ CrossIcon } alt="x" width={ 12 }/>
								{ i18n.t( "btn_deselect" ) }
							</Styled.BtnDeselect>
							:
							<></>
						}
					</Styled.FilterCities>
					<Styled.FilterPeriod>
						<LocalizationProvider dateAdapter={ AdapterDateFns }>
							<DatePicker
								PaperProps={{ 
									sx: { 
										"& .MuiTypography-root": {
											fontSize: 18
										},
										"& .MuiButtonBase-root": {
											"&.MuiPickersDay-root": {
												fontSize: 18,
												"&.Mui-selected": { 
													color: "#FFF", backgroundColor: "#0078FF" 
												}
											} 
										} 
									} 
								}}
								allowSameDateSelection
								label={ i18n.t( "filter_periodStart" ) }
								mask="__.__.____"
								inputFormat="dd.MM.yyyy"
								value={ this.state.periodStart !== null ? this.state.periodStart : null }
								onChange={ this.periodStartChange }
								renderInput={ ( props ) => ( <Styled.PickerField {...props} /> ) }
							/>
						</LocalizationProvider>
						<LocalizationProvider dateAdapter={ AdapterDateFns }>
							<DatePicker
								PaperProps={{ 
									sx: { 
										"& .MuiTypography-root": {
											fontSize: 18
										},
										"& .MuiButtonBase-root": {
											"&.MuiPickersDay-root": {
												fontSize: 18,
												"&.Mui-selected": { 
													color: "#FFF", backgroundColor: "#0078FF" 
												}
											} 
										} 
									} 
								}}
								allowSameDateSelection
								label={ i18n.t( "filter_periodEnd" ) }
								mask="__.__.____"
								inputFormat="dd.MM.yyyy"
								value={ this.state.periodEnd !== null ? this.state.periodEnd : null }
								onChange={ this.periodEndChange }
								renderInput={ ( props ) => ( <Styled.PickerField { ...props }/> ) }
							/>
						</LocalizationProvider>
						<Styled.Button
							disabled={ this.state.loading }
							variant="outlined"
							onClick={ this.getData }
						>
							<img src={ SearchIcon } alt=""/>
							<span>{ this.state.loading ? i18n.t( "btn_loading" ) : i18n.t( "btn_search" ) }</span>
						</Styled.Button>
					</Styled.FilterPeriod>
				</Styled.Filters>

				<Styled.Divider/>
				<Styled.ElementContainer>
					<ReactECharts option={ this.optionNumberOfProcessedReqs() } style={ { height: 600 } }/>
				</Styled.ElementContainer>

				<Styled.Divider/>
				<Styled.ElementContainer>
					{ 
						this.state.loading
						?
						<Box display="flex" justifyContent="center">
							<CircularProgress size={ "10vw" }/>
						</Box>
						:
						this.renderRequestTypesTable()
					}
				</Styled.ElementContainer>

				{
					this.state.cityHalls.length !== 0
					&&
					<>
						<Styled.Divider/>
						<DocumentTemplateRequests cityHalls={ this.state.cityHalls }/>
					</>
				}

				<Styled.Footer/>
			</div>
		);
	}
}
export default withStyles( classes ) ( MenuRequests );