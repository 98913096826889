const host = "https://dashboard.cancelaria.md:6061";

export const api = {
	authenticate: () => `${host}/authenticate`,
	register: () => `${host}/register`,
	dashboard: {
		getCityHalls: () => `${host}/dashboard/city-halls`,

		postGetProcessedDocumentTemplates: () => `${host}/dashboard/templates-documents-table`,

		postProcessedRequests: () => `${host}/dashboard/processed-requests`,
		postActiveUsersDistribution: () => `${host}/dashboard/active-users-distribution`,
		postActiveUsers: () => `${host}/dashboard/active-users`,

		postServiceAvgProcessingTime: () => `${host}/dashboard/service-avg-proc-time`,
		postInTimeServicesStats: () => `${host}/dashboard/intime-service-stats`,
		postTimeSeriesData: () => `${host}/dashboard/time-series-data-all`,
		postRequestTypeData: () => `${host}/dashboard/req-type-data`,
		postProcessingTimeData: () => `${host}/dashboard/process-time-data`,
		postRequestTypeDataTable: () => `${host}/dashboard/req-type-data-table-all`,

		postBeneficiaryData: () => `${host}/dashboard/benef-data-all`,
	},
}