import { styled } from "@mui/material/styles";

import TextField from "@mui/material/TextField";
import MuiDivider from "@mui/material/Divider";
import MuiChip from "@mui/material/Chip";
import MuiButton from "@mui/material/Button";
import MuiTableRow from "@mui/material/TableRow";
import MuiTableHead from "@mui/material/TableHead";

export const PickerField = styled( TextField )( () => ( {
	"& button": {
		"& svg": {
			color: "#212121"
		} 
	},
	"& input": {
		fontFamily: "Rubik", fontWight: 500, fontSize: 18,
		padding: "16px 0px 16px 16px",
		color: "#2E3236",
		"&:focus": {
			color: "#21abee"
		}
	},
	"& fieldset": {
		borderRadius: 12,
		borderColor: "#2E3236",
		borderWidth: 2
	},
	"& label": {
		fontFamily: "Rubik",
		color: "#000"
	}
} ) );

export const Divider = styled( MuiDivider )( () => ( {
	color: "#D5DEE3",
	width: "calc(100% - 100px)",
	margin: "50px auto 0 auto"
} ) );

export const BtnDeselect = styled( 'div' )( () => ( {
	fontFamily: "Rubik", fontWeight: 500, fontSize: 16,
	display: "flex", alignItems: "center", gap: 10,
	height: 44,
	backgroundColor: "#F0F3F5",
	color:"#B1C4CD",
	borderRadius: 22,
	padding: "0 18px",
	textTransform: "uppercase",
	userSelect: "none",
	"&.active": {
		cursor: "pointer",
		color: "#67767D",
		"& > img": {
			filter: "invert(46%) sepia(12%) saturate(441%) hue-rotate(154deg) brightness(94%) contrast(86%)"
		}
	}
} ) );

export const Chip = styled( MuiChip )( () => ( {
	fontFamily: "Rubik", fontWeight: 500, fontSize: 16,
	textTransform: "uppercase",
	backgroundColor: "#FFFFFF",
	border: "2px solid #2E3236",
	transition: "none",
	height: 44,
	borderRadius: 22,
	"&.selected": {
		color: "#FFFFFF",
		backgroundColor: "#0078FF",
		border: "2px solid #0078FF"
	},
	"& > span": {
		padding: "0 18px"
	},
	"&:hover": {
		backgroundColor: "#73cdff",
		color: "#FFF",
		borderColor: "#73cdff"
	}
} ) );

export const Button = styled( MuiButton )( () => ( {
	color: "#FFFFFF", backgroundColor: "#0078FF",
	fontFamily: "Rubik", fontWeight: 500, fontSize: 18,
	height: 60,
	borderRadius: 12,
	padding: "0 25px 2px 22px",
	"&:hover": {
		backgroundColor: "#0078FF"
	},
	"&.Mui-disabled": {
		color: "#B1C4CD"
	},
	"& > img": {
		marginRight: 15
	},
	"& > span": {
		marginTop: 3
	}
} ) );

export const Footer = styled( 'div' )( () => ( {
	height: 300
} ) );

export const Filters = styled( 'div' )( () => ( {
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	justifyContent: "center"
} ) );

export const FilterCities = styled( 'div' )( () => ( {
	display: "flex",
	width: 774,
	flexWrap: "wrap",
	justifyContent: "center",
	marginTop: 20,
	gap: 10
} ) );

export const FilterPeriod = styled( 'div' )( () => ( {
	marginTop: 50,
	display: "flex",
	flexDirection: "row",
	gap: 20,
	justifyContent: "center",
	alignItems: "center"
} ) );

export const Troot = styled( 'div' )( () => ( {
	overflow: "auto",
	height: "100%",
	width: "100%",
	textTransform: "uppercase",
	border: "1px solid #D5DEE3",
	"& .MuiTableRow-head": {
		"& .MuiTableCell-head": {
			fontSize : 14,
			overflowWrap: "anywhere",
			fontFamily : "Rubik",
			fontWeight : 500,
			lineHeight: 1.3,
			padding: "30px 8px",
			color: "#212121",
			borderRight: "1px solid #D5DEE3",
			borderBottom: "1px solid #D5DEE3",
			backgroundColor: "#FFF",
			"&:last-of-type": {
				borderRight: "none"
			}
		}
	}
} ) );

export const Thead = styled( MuiTableHead )( () => ( {
	position: "sticky",
	top: 0,
	color: "black",
	height: 60
} ) );

export const Table = styled( 'div' )( () => ( {
	overflow: "auto", height: "100%", width: "100%"
} ) );

export const TableRow = styled( MuiTableRow )( () => ( {
	height: 30,
	"&:first-of-type(1)": {
		borderLeft: "none"
	}
} ) );

export const ElementContainer = styled( 'div' )( () => ( {
	padding: "80px 50px"
} ) );

export const GotoBtn = styled( 'div' )( () => ( {
	cursor: "pointer",
	padding: "0.5em 1em",
	fontSize: "16px",
	border: "2px solid #000",
	width: "fit-content",
	textTransform: "uppercase",
	fontWeight: "bold"
} ) );