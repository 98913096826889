import React from "react";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import i18n from "../translation/settings";
import http from "../utils/http";
import { api } from "../api";

import {
	Table,
	TableBody,
	TableCell,
	TableRow,
	Box,
	FormControl,
	MenuItem
} from "@mui/material";

import SearchIcon from "../assets/images/Search.svg";

import { convertDateFromIsoToFormat } from "../utils/functions";

import * as Styled from "../assets/styles/Styled/Common";

class DocumentTemplateRequests extends React.Component {
	constructor( props ) {
		super( props );
		const periodEndDefault = new Date();
		const periodStartDefault = ( new Date( periodEndDefault.getFullYear(), periodEndDefault.getMonth(), 1 ) );
		// const periodStartDefault = ( new Date( periodEndDefault.getFullYear(), 0, 1 ) );
		this.state = {
			loading: false,
			cityHalls: this.props.cityHalls,
			cityHall: "",

			periodStart: periodStartDefault.toISOString(),
			periodEnd: periodEndDefault.toISOString(),
			processedDocumentTemplates: []
		}
		// this.props.changePage();
	}

	componentDidMount = async () => {
	}
	componentWillUnmount = () => {
		// bad hacky solution
		// fix Warning: Can't perform a React state update on an unmounted component
		this.setState = ( state, callback ) => {
			return;
		};
	}

	getProcessedDocumentTemplates = async () => {
		this.setState( { loading: true } );
		const accessToken = localStorage.getItem( "token" );
		const periodStart = this.state.periodStart !== null ? convertDateFromIsoToFormat( this.state.periodStart, "DD.MM.YYYY" ) : "";
		const periodEnd = this.state.periodEnd !== null ? convertDateFromIsoToFormat( this.state.periodEnd, "DD.MM.YYYY" ) : "";

		const url = api.dashboard.postGetProcessedDocumentTemplates();
		const data = {
			"city_hall_code": this.state.cityHall,
			"period_start": periodStart,
			"period_end": periodEnd
		}
		const headers = { "Authorization": `Bearer ${ accessToken }` };

		try {
			const PostProcessedDocumentTemplates = await http.post( url, data, { headers } );

			this.setState( {
				loading: false,
				processedDocumentTemplates: PostProcessedDocumentTemplates.data
			} );
		} catch ( error ) {
			console.log('error')

			this.setState( {
				loading: false,
			} );
		}
	}

	cityHallChange = ( event ) => {
		this.setState( { cityHall: event.target.value } );
	}
	periodStartChange = ( date ) => {
		if ( date instanceof Date && !isNaN( date ) ) {
			this.setState({ periodStart: new Date( date ).toISOString() });
			return;
		}
		this.setState({ periodStart: null });
	}
	periodEndChange = ( date ) => {
		if ( date instanceof Date && !isNaN( date ) ) {
			this.setState({ periodEnd: new Date( date ).toISOString() });
			return;
		}
		this.setState({ periodEnd: null });
	}

	render() {
		return(
			<>
				<Box id="treq" display={ "flex" } justifyContent={ "center" }>
					<Styled.FilterPeriod>
						<LocalizationProvider dateAdapter={ AdapterDateFns }>
							<DatePicker
								PaperProps={{ 
									sx: { 
										"& .MuiTypography-root": {
											fontSize: 18
										},
										"& .MuiButtonBase-root": {
											"&.MuiPickersDay-root": {
												fontSize: 18,
												"&.Mui-selected": { 
													color: "#FFF", backgroundColor: "#0078FF" 
												}
											} 
										} 
									} 
								}}
								allowSameDateSelection
								label={ i18n.t( "filter_periodStart" ) }
								mask="__.__.____"
								inputFormat="dd.MM.yyyy"
								value={ this.state.periodStart !== null ? this.state.periodStart : null }
								onChange={ this.periodStartChange }
								renderInput={ ( props ) => ( <Styled.PickerField {...props} /> ) }
							/>
						</LocalizationProvider>
						<LocalizationProvider dateAdapter={ AdapterDateFns }>
							<DatePicker
								PaperProps={{ 
									sx: { 
										"& .MuiTypography-root": {
											fontSize: 18
										},
										"& .MuiButtonBase-root": {
											"&.MuiPickersDay-root": {
												fontSize: 18,
												"&.Mui-selected": { 
													color: "#FFF", backgroundColor: "#0078FF" 
												}
											} 
										} 
									} 
								}}
								allowSameDateSelection
								label={ i18n.t( "filter_periodEnd" ) }
								mask="__.__.____"
								inputFormat="dd.MM.yyyy"
								value={ this.state.periodEnd !== null ? this.state.periodEnd : null }
								onChange={ this.periodEndChange }
								renderInput={ ( props ) => ( <Styled.PickerField { ...props }/> ) }
							/>
						</LocalizationProvider>
						<Box sx={ { minWidth: 200 } }>
							<FormControl fullWidth>
								<Styled.PickerField
									select
									id="cityhall-select"
									value={ this.state.cityHall }
									label={ i18n.t( "filter_cityHall" ) }
									onChange={ this.cityHallChange }
								>
									{ this.state.cityHalls.map( ( cityHall, index ) => {
										return (
											<MenuItem
												key={ cityHall.code }
												value={ cityHall.code }
											>{ cityHall.name }</MenuItem>
										);
									} ) }
								</Styled.PickerField>
							</FormControl>
						</Box>
						<Styled.Button
							disabled={ this.state.loading || this.state.cityHall === "" }
							variant="outlined"
							onClick={ this.getProcessedDocumentTemplates }
						>
							<img src={ SearchIcon } alt=""/>
							<span>{ this.state.loading ? i18n.t( "btn_loading" ) : i18n.t( "btn_search" ) }</span>
						</Styled.Button>
					</Styled.FilterPeriod>
				</Box>

				<Styled.ElementContainer sx={{ minHeight: "200px" }}>
					<Box padding={ "0 0 50px" } display={ "flex" } justifyContent={ "center" }>
						<div style={{ fontFamily: "Rubik", fontWeight: 500, color: "#212121", fontSize: 18 }}>{ i18n.t( "table_template_requests" ) }</div>
					</Box>
					<Styled.Troot>
						<Table>
							<Styled.Thead>
								<TableRow>
									<TableCell>{ i18n.t( "table_template_requests_head_1" ) }</TableCell>
									<TableCell align="center">{ i18n.t( "table_template_requests_head_2" ) }</TableCell>
								</TableRow>
							</Styled.Thead>
							<TableBody>
							{
								this.state.processedDocumentTemplates.length !== 0
								?
								this.state.processedDocumentTemplates.map( (cityHall, index) => {
									return (
										<TableRow key={ index }>
											<TableCell>{ cityHall.DOCUMENT_TEMPLATE_NAME }</TableCell>
											<TableCell align="center">{ cityHall.DOCUMENTS_COUNT }</TableCell>
										</TableRow>
									);
								} )
								:
								<TableRow>
									<TableCell colSpan={ 2 } align="center">{ i18n.t( "no_data" ) }</TableCell>
								</TableRow>
							}
							</TableBody>
						</Table>
					</Styled.Troot>
				</Styled.ElementContainer>
			</>
		)
	}
}

export default ( DocumentTemplateRequests );