import React, { Component } from "react";
import "./assets/styles/App.css";
import { BrowserRouter, Routes, Route, Navigate, useLocation } from "react-router-dom";
import Login from "./pages/Login";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import Dashboard from "./pages/Main";

function PrivateRoute( props ) {
	let location = useLocation();
	const { isLogined, children } = props;
	if( isLogined === false ) {
		// Redirect them to the /login page, but save the current location they were
		// trying to go to when they were redirected. This allows us to send them
		// along to that page after they login, which is a nicer user experience
		// than dropping them off on the home page.
		return <Navigate to="/login" state={ { from: location } } replace />;
	}  
	return children;
}
function PublicRoute( props ) {
	let location = useLocation();
	const { isLogined, children } = props;
	if( isLogined === true ) {
		// Redirect them to the /login page, but save the current location they were
		// trying to go to when they were redirected. This allows us to send them
		// along to that page after they login, which is a nicer user experience
		// than dropping them off on the home page.

		// SHOULD BE CHANGED TO ROLE: admin -> /admin, dashboard -> /data
		return <Navigate to="/data" state={ { from: location } } replace />;
	}  
	return children;
}

const theme = createTheme( {
	palette: {
		primary: {
			main: "#21abee",
		},
		secondary: {
			main: "#2d3236"
		}
	},
	components: {
		MuiTable: {
			styleOverrides: {
				root: {
					borderCollapse: "separate",
				}
			}
		},
		MuiTableBody: {
			styleOverrides: {
				root: {
					"& .MuiTableRow-root": {
						"&:first-of-type":{
							"& .MuiTableCell-root": {
								borderTop: "none",
							}
						},
						"& .MuiTableCell-root": {
							fontSize : 14,
							overflowWrap: "anywhere",
							fontFamily : "Rubik",
							color: "black",
							padding: "15px 8px",
							borderRight: "1px solid #D5DEE3",
							borderTop: "1px solid #D5DEE3",
							borderBottom: "none",
							backgroundColor: "#F5F7F8",
							"&:first-of-type": {
								backgroundColor: "#FFF",
							},
							"&:last-of-type": {
								borderRight: "none"
							}
						},
						"&:hover" : {
							cursor: "pointer",
							"& .MuiTableCell-body": { 
								backgroundColor : "#0078FF",
								color: "white" 
							}
						}
					}
				}
			}
		},
	},
} );

class App extends Component {

	constructor( props ) {
		super( props );
		this.state = {
			isLogined: localStorage.getItem( "isLogined" ) === "1" ? true : false,
			userName: localStorage.getItem( "userName" ) !== null ? localStorage.getItem( "userName" ) : "",
			fullName: "",
			role: "dashboard"
		}
	}

	login = ( userName, token ) => {
		this.setState( { isLogined: true, userName: userName } );
		localStorage.setItem( "isLogined", "1" );
		localStorage.setItem( "userName", userName );
		localStorage.setItem( "token", token );
	}
	logout = () => {
		localStorage.setItem( "isLogined", "0" );
		localStorage.setItem( "userName", "" );
		localStorage.setItem( "token", "" );
		this.setState( { isLogined: false, userName: "", fullName: "" } );
	}

	render() {
		return (
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={ theme }>
					<BrowserRouter>
						<Routes>
							<Route path="/login" element={
								<PublicRoute isLogined={ this.state.isLogined }>
									<Login login={ this.login }/>
								</PublicRoute>
							} />
							<Route path="/data/*" element={
								<PrivateRoute isLogined={ this.state.isLogined }>
									<Dashboard logout={ this.logout }/>
								</PrivateRoute>
							}/>
							<Route path="*" element={
								<Navigate to="/data"/>
							} />
						</Routes>
					</BrowserRouter>
				</ThemeProvider>
			</StyledEngineProvider>
		);
	}
}
export default App;
